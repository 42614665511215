import { Route, Routes } from 'react-router-dom'
import { NotFound } from 'ui/molecules/NotFound'
import { TermsAndConditionsContent } from './components/TermsAndConditionsContent'
import { URLS } from 'constants/urls'
import { RentalIncludes } from './components/RentalIncludes'
import { RentalExcludes } from './components/RentalExcludes'
import { FuelPolicy } from './components/FuelPolicy'
import { InsuranceIncluded } from './components/InsuranceIncluded'
import { DriverCreditCard } from './components/DriverCreditCard'
import { ExcessAndDeposit } from './components/ExcessAndDeposit'
import { CrossBorderPolicy } from './components/CrossBorderPolicy'
import { DriverRequirements } from './components/DriverRequirements'
import { FC, useState } from 'react'
import { TERM_CONDITIONS_TYPES } from 'constants/termsAndConditions'
import { breadcrumbs as b } from 'constants/breadcrumbs'
import { IContentProps } from './types'
import { TERMS_WITHOUTH_HISTORY } from './components/TermsAndConditionsContent/constants'

export const RENTAL_INCLUDES_TYPE = 2

export const TermsAndConditions = () => {
  const [reloadKey, setReloadKey] = useState(0)

  const reload = () => setReloadKey((key) => key + 1)

  const getRoutes = (
    Component: FC<IContentProps>,
    path: string,
    termConditionTypesId: TERM_CONDITIONS_TYPES
  ) => (
    <>
      <Route
        path={path}
        element={
          <TermsAndConditionsContent
            key={reloadKey}
            current={path}
            termConditionTypesId={termConditionTypesId}
          >
            {(data, historyList, metaData) => (
              <Component
                reload={reload}
                data={
                  TERMS_WITHOUTH_HISTORY.includes(termConditionTypesId)
                    ? historyList?.[0]
                    : data
                }
                isHistoryExist={!!historyList?.length}
                metaData={metaData}
              />
            )}
          </TermsAndConditionsContent>
        }
      />
      <Route
        path={`${path}/:companyId`}
        element={
          <TermsAndConditionsContent
            key={reloadKey}
            breadcrumbs={[b.profileList]}
            current={path}
            termConditionTypesId={termConditionTypesId}
          >
            {(data, historyList, metaData) => (
              <Component
                reload={reload}
                data={
                  TERMS_WITHOUTH_HISTORY.includes(termConditionTypesId)
                    ? historyList?.[0]
                    : data
                }
                isHistoryExist={!!historyList?.length}
                metaData={metaData}
              />
            )}
          </TermsAndConditionsContent>
        }
      />
    </>
  )

  return (
    <Routes>
      {getRoutes(
        RentalIncludes,
        URLS.RENTAL_INCLUDES,
        TERM_CONDITIONS_TYPES.RentalIncludes
      )}
      {getRoutes(
        RentalExcludes,
        URLS.RENTAL_EXCLUDES,
        TERM_CONDITIONS_TYPES.RentalExcludes
      )}
      {getRoutes(
        DriverRequirements,
        URLS.DRIVER_REQUIREMENTS,
        TERM_CONDITIONS_TYPES.DriverRequirements
      )}
      {getRoutes(
        FuelPolicy,
        URLS.FUEL_POLICY,
        TERM_CONDITIONS_TYPES.FuelPolicy
      )}
      {getRoutes(
        CrossBorderPolicy,
        URLS.CROSS_BORDER_POLICY,
        TERM_CONDITIONS_TYPES.CrossBorderPolicy
      )}
      {getRoutes(
        InsuranceIncluded,
        URLS.INSURANCE_INCLUDED,
        TERM_CONDITIONS_TYPES.InsuranceIncluded
      )}
      {getRoutes(
        DriverCreditCard,
        URLS.DRIVER_CREDIT,
        TERM_CONDITIONS_TYPES.DriverCreditCard
      )}
      {getRoutes(
        ExcessAndDeposit,
        URLS.EXCESS_DEPOSIT,
        TERM_CONDITIONS_TYPES.ExcessDeposit
      )}
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
