import {
  Button,
  Typography,
  LazyImage,
  FormContext,
} from '@frontend/design_system'
import { useNavigate } from 'react-router-dom'
import { CANCEL, SAVE } from 'texts/uiTexts'
import { URLS } from 'constants/urls'
import noCarImage from 'assets/img/no-car-image.png'
import { RightSideProps } from './types'
import { REQUIRED_FIELDS } from '../../constants'
import { useCheckRequiredFields } from 'hooks/useCheckRequiredFields'
import { VehicleInformationBlock } from '../VehicleInformationBlock'
import { CAR_TABS, VEHICLE_DETAILS } from 'texts/vehicleDetails'
import { DriverAgeRangeBlock } from '../DriverAgeRangeBlock'
import { useEffect, useMemo, useState } from 'react'
import { carTypeImages } from '../VehicleInformationBlock/constants'
import { useApiRequest } from 'hooks/useApiRequest'
import { fleetApi } from 'api'
import { replaceShortNotAvailable } from '../../utils'
import { imageToBase64 } from 'utils/imageToBase64'
import { ACCOUNT_STATUSES } from 'api/auth/constants'
import { useAppSelector } from 'redux/hooks'
import { isOperatorSelector, userDataSelector } from 'redux/login/selectors'
import { base64DataRegex } from 'constants/regex'
import { useCompanyId } from 'hooks/useCompanyId'
import styles from './styles.module.scss'

export const RightSide = ({
  formValues,
  activeKey,
  isDirty,
  isLoading,
  isEditing,
  vehicleDetailsData,
  setBase64Image,
  handleChangeSipp,
  sipp,
}: RightSideProps) => {
  const navigate = useNavigate()
  const [bcrmCarGroup, setBcrmCarGroup] = useState('')
  const { isDisabledFormButton } = useCheckRequiredFields(
    REQUIRED_FIELDS,
    FormContext
  )
  const isOperator = useAppSelector(isOperatorSelector)
  const { accountStatus } = useAppSelector(userDataSelector)
  const companyId = useCompanyId()
  const isAccountApproved =
    isOperator || accountStatus === ACCOUNT_STATUSES.APPROVED
  const { airConditioning, transmission, fuel, carCategoryId } = formValues

  const currentCategoryName = useMemo(
    () =>
      vehicleDetailsData?.carCategories.find((el) => el.id === carCategoryId)
        ?.name,
    [vehicleDetailsData, carCategoryId]
  )

  const currentCar = useMemo(
    () => vehicleDetailsData?.cars?.find((el) => el.id === formValues.car),
    [vehicleDetailsData, formValues]
  )

  useEffect(() => {
    if (!currentCar?.meta?.image) {
      const convertImageToBase64 = async () => {
        const res = await imageToBase64(
          carTypeImages[bcrmCarGroup as keyof typeof carTypeImages] ||
            noCarImage
        )
        setBase64Image((res as string).replace(base64DataRegex, ''))
      }
      convertImageToBase64()
    }
  }, [currentCar, bcrmCarGroup])

  const handleCancelClick = () => {
    if (isAccountApproved) {
      const path = isOperator
        ? `${URLS.FLEET}${URLS.VEHICLES}/${companyId}`
        : `${URLS.FLEET}${URLS.VEHICLES}`
      navigate(path)
    } else {
      navigate(`${URLS.FLEET}${URLS.VEHICLES}/add`)
    }
  }
  const currentType = useMemo(
    () =>
      vehicleDetailsData?.carTypes?.find((el) => el.id === formValues.carType),
    [vehicleDetailsData, formValues]
  )

  const getSippByVehicleInfoRequest = useApiRequest((vehicleInfo) =>
    fleetApi.getSippByVehicleInfo(vehicleInfo)
  )

  const getBcrmCarGroupImageRequest = useApiRequest(
    (carCategoryId, carType, fuel) =>
      fleetApi.getBcrmCarGroupImage(carCategoryId, carType, fuel)
  )

  useEffect(() => {
    if (
      airConditioning &&
      transmission &&
      fuel &&
      currentType?.id &&
      carCategoryId
    ) {
      const fetchSipp = async () => {
        const response = await getSippByVehicleInfoRequest.apiRequest({
          airConditioning: replaceShortNotAvailable(airConditioning),
          transmission: replaceShortNotAvailable(transmission),
          fuel: replaceShortNotAvailable(fuel),
          carCategoryId,
          carType: currentType.id,
        })
        if (response) {
          handleChangeSipp(response.data.sipp)
        }
      }
      fetchSipp()
    }
  }, [airConditioning, transmission, fuel, currentType?.id, carCategoryId])

  useEffect(() => {
    if (carCategoryId && currentType?.id) {
      const fetchBcrmCarType = async () => {
        const response = await getBcrmCarGroupImageRequest.apiRequest(
          carCategoryId,
          currentType.id,
          replaceShortNotAvailable(fuel)
        )
        if (response) {
          setBcrmCarGroup(response.data.carGroupName)
        }
      }
      fetchBcrmCarType()
    }
  }, [carCategoryId, currentType, fuel])

  const renderInfoContent = (formValues: Record<string, unknown>) => {
    if (activeKey === CAR_TABS.DETAILS) {
      return (
        <VehicleInformationBlock
          vehicleDetailsData={vehicleDetailsData}
          values={{
            ...formValues,
            carCategoryId: currentCategoryName,
            sippCode: sipp || formValues.sippCode,
            carType: currentType?.name,
          }}
        />
      )
    }
    if (activeKey === CAR_TABS.DRIVER_AGE_RANGE) {
      return <DriverAgeRangeBlock values={formValues} />
    }
  }

  return (
    <div className={styles.container}>
      <div>
        <Typography name="titleWBold" className={styles['title']}>
          {currentCar?.name || formValues.car}
        </Typography>
        <div className={styles['car-image-wrapper']}>
          <LazyImage
            src={currentCar?.meta?.image || ''}
            width={166}
            height={100}
            defaultSrc={
              carTypeImages[bcrmCarGroup as keyof typeof carTypeImages] ||
              noCarImage
            }
          />
        </div>
        {renderInfoContent(formValues)}
      </div>
      <div className={styles['button-wrapper']}>
        {isEditing ? (
          <>
            <div className={styles['button-wrapper-short']}>
              <Button
                onClick={handleCancelClick}
                loading={isLoading}
                label={CANCEL}
                size="large"
                variant="secondary-border"
              />
            </div>
            <div className={styles['button-wrapper-short']}>
              <Button
                htmlType="submit"
                loading={isLoading}
                label={SAVE}
                disabled={!isDirty}
                size="large"
              />
            </div>
          </>
        ) : (
          <Button
            htmlType="submit"
            loading={isLoading}
            label={VEHICLE_DETAILS.BUTTONS.ADD_CAR}
            disabled={isDisabledFormButton}
            size="large"
          />
        )}
      </div>
    </div>
  )
}
