import { FiltersState } from 'redux/filters/types'
import { SortingState } from 'redux/sorting/types'

export const ACTIVITY_HISTORY_FILTER_KEY = 'activityHistory'
export const APPLICATIONS_FILTER_KEY = 'applications'
export const PRICE_LIST_FILTER_KEY = 'priceList'
export const STOP_SALES_FILTER_KEY = 'stopSales'
export const PROFILE_LIST_FILTER_KEY = 'profileList'
export const PROFILE_ARCHIVE_FILTER_KEY = 'profileArchive'
export const LOCATIONS_FILTER_KEY = 'locations'
export const EQUIPMENTS_FILTER_KEY = 'equipments'
export const CARS_FILTER_KEY = 'cars'

export const PROFILE_LIST_FILTER_KEYS = {
  companyIds: 'id',
  companyNames: 'name',
  countries: 'country',
  statuses: 'accountstatus',
}

export const LOCATIONS_FILTER_KEYS = {
  countries: 'country',
  locations: 'locationname',
}

export const CARS_FILTERS_KEYS = {
  carModel: 'name',
  companyClasses: 'companyclass',
  sipp: 'sippcode',
  carCategories: 'carcategory',
  refersToLocation: 'locationname',
}

export const PRICE_LIST_FILTERS_KEYS = {
  countries: 'country',
  locations: 'location',
  startperiod: 'startperiod',
  years: 'year',
}

export const STOP_SALES_FILTERS_KEYS = {
  countries: 'country',
  cities: 'city',
  locations: 'location',
  carClasses: 'carClass',
  created: 'created',
  years: 'year',
}

export const ACTIVITY_HISTORY_FILTERS_KEYS = {
  categories: 'category',
  users: 'userId',
  companies: 'companyName',
  activityTypes: 'actionType',
  elements: 'entityId',
  auditDate: 'auditDate',
}

export const APPLICATIONS_FILTERS_KEYS = {
  companies: 'CompanyName',
  emails: 'email',
  applicationIds: 'id',
  submissionDates: 'submissionDate',
  processDate: 'processDate',
}

export const EQUIPMENTS_FILTERS_KEY = {
  equipment: 'name',
  sum: 'sum',
  count: 'count',
  maxsum: 'maxsum',
}

export const FILTER_KEYS: {
  [key in keyof FiltersState['filters']]: { [key: string]: string }
} = {
  profileList: PROFILE_LIST_FILTER_KEYS,
  profileArchive: PROFILE_LIST_FILTER_KEYS,
  locations: LOCATIONS_FILTER_KEYS,
  priceList: PRICE_LIST_FILTERS_KEYS,
  activityHistory: ACTIVITY_HISTORY_FILTERS_KEYS,
  applications: APPLICATIONS_FILTERS_KEYS,
  stopSales: STOP_SALES_FILTERS_KEYS,
  cars: CARS_FILTERS_KEYS,
  equipments: EQUIPMENTS_FILTERS_KEY,
}

export const SORTING_KEYS: {
  [key in keyof SortingState['sortingData']]: { [key: string]: string }
} = {
  profileList: PROFILE_LIST_FILTER_KEYS,
  profileArchive: PROFILE_LIST_FILTER_KEYS,
  locations: LOCATIONS_FILTER_KEYS,
  priceList: PRICE_LIST_FILTERS_KEYS,
  activityHistory: ACTIVITY_HISTORY_FILTERS_KEYS,
  applications: APPLICATIONS_FILTERS_KEYS,
  stopSales: STOP_SALES_FILTERS_KEYS,
  cars: CARS_FILTERS_KEYS,
  equipments: EQUIPMENTS_FILTERS_KEY,
}

export const ITEMS_WITH_SEARCH: { [key: string]: string[] } = {
  profileList: ['companyIds', 'companyNames', 'countries'],
  profileArchive: Object.keys(PROFILE_LIST_FILTER_KEYS),
  locations: Object.keys(LOCATIONS_FILTER_KEYS),
  priceList: Object.keys(PRICE_LIST_FILTERS_KEYS),
  cars: Object.keys(CARS_FILTERS_KEYS),
  stopSales: Object.keys(STOP_SALES_FILTERS_KEYS),
  activityHistory: ['users', 'companies', 'elements', 'applicationIds'],
  applications: ['emails', 'companies', 'applicationIds', 'submissionDates'],
  equipments: ['equipment'],
}

export const ITEMS_WITH_SORTING: { [key: string]: string[] } = {
  profileList: Object.keys(PROFILE_LIST_FILTER_KEYS),
  locations: Object.keys(LOCATIONS_FILTER_KEYS),
  profileArchive: ['companyIds', 'companyNames', 'countries'],
  priceList: ['startperiod'],
  cars: ['carModel', 'companyClasses', 'sipp', 'carCategories'],
  stopSales: ['countries', 'carClasses', 'created'],
  activityHistory: [
    'users',
    'companies',
    'elements',
    'applicationIds',
    'emails',
    'auditDate',
  ],
  equipments: ['equipment', 'sum', 'maxsum', 'count'],
  applications: ['companies', 'submissionDates', 'processDate'],
}

export const ITEMS_WITHOUTH_FILTERING: { [key: string]: string[] } = {
  activityHistory: ['auditDate', 'submission', 'processDate'],
  priceList: ['startperiod'],
  stopSales: ['created'],
  equipments: ['count', 'sum', 'maxsum'],
  applications: ['processDate'],
}

export const ITEMS_WITHOUT_SELECT_ALL: { [key: string]: string[] } = {
  profileList: ['companyIds', 'companyNames'],
  profileArchive: ['companyIds', 'companyNames'],
}
