import React, { useRef } from 'react'
import { useForm } from './hooks/useForm'
import { ContextType, FormProps } from './types'
import { isNotEmptyValueObject } from 'utils/object'
import { removeSpaces } from './utils'
import { useValidate } from './hooks/useValidate'
import styles from './components/FormItem/styles.module.scss'

export const FormContext = React.createContext<ContextType>({} as ContextType)

export const Form = ({
  children,
  onSubmit,
  initValues,
  className,
  isMountValidate,
  noValidate,
}: FormProps) => {
  const formRef = useRef<HTMLFormElement | null>(null)
  const {
    changeValues: setValues,
    values,
    changeErrors: setErrors,
    errors,
    isDirty,
    setDirty,
  } = useForm({ initValues })

  const { setRules, onValidate } = useValidate({
    isMountValidate,
    values,
    setErrors,
  })

  const checkValidation = () => {
    const newErrors = onValidate()
    const isValid = !isNotEmptyValueObject(newErrors)

    if (!isValid) {
      requestAnimationFrame(() => {
        const field = formRef.current?.querySelector(
          `.${styles['error-field']}`
        )

        if (field) {
          field.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })
        }
      })
    }

    return { isValid, newErrors }
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    const { isValid, newErrors } = checkValidation()

    const isResetDirty = onSubmit(removeSpaces(values), isValid, newErrors)
    setDirty(!isResetDirty)
  }

  return (
    <FormContext.Provider
      value={{
        errors,
        setErrors,
        values,
        setValues,
        setRules,
        checkValidation,
      }}
    >
      <form
        className={className}
        onSubmit={handleSubmit}
        ref={formRef}
        noValidate={noValidate}
      >
        {typeof children === 'function'
          ? children({ errors, values, isDirty })
          : children}
      </form>
    </FormContext.Provider>
  )
}
