import {
  FormItem,
  RadioButton,
  Textarea,
  FormAlertLabel,
  Typography,
  Select,
} from '@frontend/design_system'
import { useMemo } from 'react'
import {
  APPLICATIONS_STATUS_VALUES,
  APPLICATION_FORM_VALUES,
} from 'texts/applications'
import { useSelectValues } from 'hooks/useSelectValues'
import { StatusInfoProps } from './types'
import {
  APPLICATION_STATUS_SELECT_VALUES,
  RADIO_BUTTONS,
  REJECT_REASON_VALUES,
  settings,
} from './constants'
import { joinClasses } from 'utils/joinClasses'
import { APPLICATION_STATUS } from 'modules/Applications/constants'
import { FormItemChildren } from 'types/form'
import { ApplicationStatus } from 'api/applications/types'
import styles from './styles.module.scss'

export const StatusInfo = ({ status, values, readonly }: StatusInfoProps) => {
  const statuses =
    APPLICATION_STATUS_SELECT_VALUES[status]?.map((selectValue) => ({
      name: APPLICATIONS_STATUS_VALUES[selectValue as ApplicationStatus],
      id: selectValue,
    })) || []

  const reasons = Object.entries(REJECT_REASON_VALUES).map(([key, value]) => ({
    name: value,
    id: key,
  }))
  const { shouldSelectRender, shouldSelectDisable, shouldTextareaDisable } =
    settings[status]

  const preparedStatuses = useSelectValues(statuses)
  const preapredReason = useSelectValues(reasons)

  const isShouldCommentFieldRender =
    !!values.comment ||
    (values.status === APPLICATION_STATUS.WaitingForCall &&
      status === APPLICATION_STATUS.WaitingList)

  const isShouldRadioButtonRender =
    values.status === APPLICATION_STATUS.Approve ||
    status === APPLICATION_STATUS.Approve

  const rules = useMemo(
    () => ({
      status: [
        {
          type: 'required',
          message: APPLICATION_FORM_VALUES.STATUS.REQUIRED_MESSAGE,
        },
      ],
      reasonType: [
        {
          type: 'required',
          message: APPLICATION_FORM_VALUES.REASON_TYPE.REQUIRED_MESSAGE,
        },
      ],
      reason: [
        {
          type: 'required',
          message: APPLICATION_FORM_VALUES.REASON.REQUIRED_MESSAGE,
        },
      ],
      connectionType: [
        {
          type: 'required',
          message: APPLICATION_FORM_VALUES.CONNECTION_TYPE.REQUIRED_MESSAGE,
        },
      ],
    }),
    []
  )

  return (
    <>
      <div
        className={joinClasses(styles['status-block'], [
          styles.reverse,
          status === APPLICATION_STATUS.WaitingList,
        ])}
      >
        {isShouldCommentFieldRender && (
          <div className={styles['form-items-row']}>
            <FormItem id={APPLICATION_FORM_VALUES.COMMENT.ID}>
              {({ value, error, onChange }: FormItemChildren) => (
                <Textarea
                  label={APPLICATION_FORM_VALUES.COMMENT.LABEL}
                  value={value}
                  disabled={readonly || shouldTextareaDisable}
                  onChange={onChange}
                  placeholder={APPLICATION_FORM_VALUES.COMMENT.PLACEHOLDER}
                  error={error}
                />
              )}
            </FormItem>
          </div>
        )}
        {shouldSelectRender && (
          <div className={styles['form-items-row']}>
            <FormItem
              id={APPLICATION_FORM_VALUES.STATUS.ID}
              className={styles['form-item']}
              rules={rules.status}
            >
              {({ value, error, onChange }: FormItemChildren) => (
                <Select
                  selectedValue={value}
                  items={preparedStatuses}
                  placeholder={APPLICATION_FORM_VALUES.STATUS.PLACEHOLDER}
                  size="large"
                  error={error}
                  disabled={readonly || shouldSelectDisable}
                  onChange={onChange}
                  isRequired
                  label={APPLICATION_FORM_VALUES.STATUS.LABEL}
                />
              )}
            </FormItem>
          </div>
        )}
      </div>
      {(values.status === APPLICATION_STATUS.Reject ||
        status === APPLICATION_STATUS.Reject) && (
        <>
          <div className={styles['form-items-row']}>
            <FormItem
              id={APPLICATION_FORM_VALUES.REASON_TYPE.ID}
              className={styles['form-item']}
              rules={rules.reasonType}
            >
              {({ value, error, onChange }: FormItemChildren) => (
                <Select
                  selectedValue={value}
                  items={preapredReason}
                  placeholder={APPLICATION_FORM_VALUES.REASON_TYPE.PLACEHOLDER}
                  size="large"
                  error={error}
                  disabled={readonly || status === APPLICATION_STATUS.Reject}
                  onChange={onChange}
                  isRequired
                  label={APPLICATION_FORM_VALUES.REASON_TYPE.LABEL}
                />
              )}
            </FormItem>
          </div>
          {values.rejectReasonType === REJECT_REASON_VALUES.Other && (
            <div className={styles['form-items-row']}>
              <FormItem
                id={APPLICATION_FORM_VALUES.REASON.ID}
                rules={rules.reason}
              >
                {({ value, error, onChange }: FormItemChildren) => (
                  <Textarea
                    label={APPLICATION_FORM_VALUES.REASON.LABEL}
                    value={value}
                    disabled={
                      readonly || status === APPLICATIONS_STATUS_VALUES.Reject
                    }
                    onChange={onChange}
                    placeholder={APPLICATION_FORM_VALUES.REASON.PLACEHOLDER}
                    error={error}
                    isRequired
                  />
                )}
              </FormItem>
            </div>
          )}
        </>
      )}
      {isShouldRadioButtonRender && (
        <FormItem
          id={APPLICATION_FORM_VALUES.CONNECTION_TYPE.ID}
          rules={rules.connectionType}
        >
          {({ value, onChange, error }: FormItemChildren) => {
            const radioButtons =
              readonly && value
                ? RADIO_BUTTONS.filter((el) => el.id === value)
                : RADIO_BUTTONS

            return (
              <>
                <Typography
                  name="body1WBold"
                  html={APPLICATION_FORM_VALUES.CONNECTION_TYPE.LABEL}
                  markColor="red600Master"
                  Tag="p"
                  className={styles['connection-type-title']}
                ></Typography>
                <div className={styles['radio-button']}>
                  {radioButtons?.map(({ label, id }) => (
                    <RadioButton
                      labelTypographyName="Button2"
                      labelColor="grey500"
                      key={id}
                      id={id}
                      label={label}
                      disabled={
                        readonly || status === APPLICATION_STATUS.Approve
                      }
                      onChange={onChange}
                      checked={id === value}
                    />
                  ))}
                </div>
                {error && (
                  <FormAlertLabel
                    label={error}
                    position="static"
                    type="error"
                    typographyName="body2WMedium"
                  />
                )}
              </>
            )
          }}
        </FormItem>
      )}
    </>
  )
}
