import { TYPOGRAPHY_COLORS } from 'constants/TypographyColors'
import { SVGIcon } from './SvgIcon/SvgIcon'
import { IconProps } from './types'

export const ArchiveIcon = ({
  color = 'grey400',
  size,
  className,
  onClick,
  dataTestId,
}: IconProps) => (
  <SVGIcon
    color={color}
    viewBox="0 0 16 16"
    size={size}
    dataTestId={dataTestId}
    className={className}
    onClick={onClick}
  >
    <path
      d="M14 5.33301V11.9997C14 13.1042 13.1046 13.9997 12 13.9997H4C2.89543 13.9997 2 13.1042 2 11.9997V5.33301"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M14.3346 2H1.66797C1.11568 2 0.667969 2.44771 0.667969 3V4.33333C0.667969 4.88562 1.11568 5.33333 1.66797 5.33333H14.3346C14.8869 5.33333 15.3346 4.88562 15.3346 4.33333V3C15.3346 2.44772 14.8869 2 14.3346 2Z"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M6.66797 8H9.33464"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SVGIcon>
)
