import { useState, useMemo, useEffect, memo } from 'react'
import { useNavigate } from 'react-router-dom'
import { URLS } from 'constants/urls'
import { REGISTRATION_STATUSES } from 'api/auth/constants'
import { useAppDispatch } from 'redux/hooks'
import { setPageStatus } from 'redux/login/slice'
import { Confirmation } from 'ui/molecules/Confirmation'
import { useApiRequest } from 'hooks/useApiRequest'
import { setNotificationMessage } from 'redux/notifications/slice'
import { Typography, OriginButton, LazyImage } from '@frontend/design_system'
import { fleetApi, FleetApiTypes } from 'api'
import { Table } from 'ui/molecules/Table'
import { COMPLETE_CONFIRMATION } from 'texts/common'
import { headItems } from '../../constants'
import { SUCCESS_VEHICLES_UPDATE, TABLE_INFO } from 'texts/vehicleDetails'
import { VEHICLES, VEHICLES_DELETE_CONFIRMATION } from 'texts/vehicles'
import { VehicleTableProps } from './types'
import { SEQUENCE_NUMBER } from 'texts/uiTexts'
import { TableTooltip } from 'ui/molecules/TableTooltip'
import styles from './styles.module.scss'
import { useCompanyId } from 'hooks/useCompanyId'

const PAGE_SIZE = 100

export const VehiclesTable = memo(
  ({ isDirty, currentId }: VehicleTableProps) => {
    const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false)
    const [isRemoveModalOpen, setRemoveModalOpen] = useState(false)
    const [isEditingModalOpen, setEditingModalOpen] = useState(false)
    const [selectedCar, setSelectedCar] =
      useState<FleetApiTypes.Vehicle | null>(null)
    const [carList, setCarList] =
      useState<FleetApiTypes.GetVehiclesListResponse | null>(null)
    const companyId = useCompanyId()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const deleteCarRequest = useApiRequest((vehicleId) =>
      fleetApi.deleteVehicle(vehicleId)
    )

    const carListRequest = useApiRequest((companyId) =>
      fleetApi.getVehiclesList(0, PAGE_SIZE, companyId)
    )

    const carCompleteRequest = useApiRequest((companyId) =>
      fleetApi.vehicleComplete(companyId)
    )

    const getCarListData = async (companyId: string | number) => {
      const response = await carListRequest.apiRequest(companyId)
      if (response?.data) {
        setCarList(response.data)
      }
    }

    const handleModalShow = () => {
      if (isDirty) {
        setEditingModalOpen(true)
      } else {
        setConfirmationModalOpen(true)
      }
    }

    useEffect(() => {
      if (companyId) {
        getCarListData(companyId)
      }
    }, [companyId])

    const handleRowClick = (id: string) => {
      navigate(`${URLS.FLEET}${URLS.VEHICLES}/${companyId}/${id}/edit`)
    }

    const handleDeleteCar = (id: string) => {
      const car = carList?.vehicles.pageItems.find(
        (item) => item.uniqueId === id
      )
      if (car) {
        setRemoveModalOpen(true)
        setSelectedCar(car)
      }
    }

    const handleRemoveConfirm = async () => {
      if (selectedCar && companyId) {
        const response = await deleteCarRequest.apiRequest(selectedCar.uniqueId)
        if (response) {
          dispatch(
            setNotificationMessage({
              notificationMessage: VEHICLES_DELETE_CONFIRMATION.SUCCESS,
            })
          )
          getCarListData(companyId)
        }
        setRemoveModalOpen(false)
      }
    }

    const handleEditingModalClose = () => {
      setEditingModalOpen(false)
    }

    const handleModalClose = () => {
      setConfirmationModalOpen(false)
    }

    const handleRemoveModalClose = () => {
      setSelectedCar(null)
      setRemoveModalOpen(false)
    }

    const handleCompeteConfirm = async () => {
      if (companyId) {
        handleModalClose()
        handleEditingModalClose()
        const response = await carCompleteRequest.apiRequest(companyId)
        if (response) {
          dispatch(
            setNotificationMessage({
              notificationMessage: SUCCESS_VEHICLES_UPDATE,
            })
          )
          dispatch(setPageStatus({ carsStatus: REGISTRATION_STATUSES.PENDING }))
          navigate(`${URLS.FLEET}${URLS.VEHICLES}`)
        }
        setRemoveModalOpen(false)
      }
    }

    const formattedBodyItems = useMemo(
      () =>
        carList?.vehicles.pageItems.map((item, i) => {
          const sequenceNumber = i + 1
          return {
            items: [
              sequenceNumber,
              <div className={styles.vehicle}>
                <LazyImage src={item.image} className={styles.image} />
                <Typography name="Subtitle7">{item.name}</Typography>
              </div>,
              item.companyClass,
              item.sippCode,
              item.groupName,
              <TableTooltip
                title={
                  item.hirePoints?.length ? item.hirePoints?.join(', ') : ''
                }
                onClick={() => handleRowClick(item.uniqueId)}
              />,
            ],
            onClick: () => {
              handleRowClick(item.uniqueId)
            },
            removeRowClick: () => {
              handleDeleteCar(item.uniqueId)
            },
            isActive: item.uniqueId === currentId,
          }
        }),
      [carList]
    )

    const formattedHeadItems = [SEQUENCE_NUMBER, ...headItems].map((el) => ({
      value: <Typography name="Subtitle3">{el}</Typography>,
    }))

    return (
      <>
        {isEditingModalOpen && (
          <Confirmation
            confirmModalProps={{
              question: COMPLETE_CONFIRMATION.EDIT_MODAL.QUESTION,
              onConfirm: handleCompeteConfirm,
              onClose: handleEditingModalClose,
              title: COMPLETE_CONFIRMATION.EDIT_MODAL.TITLE,
              confirmLabel: COMPLETE_CONFIRMATION.EDIT_MODAL.BUTTON_LABEL,
              declineLabel: COMPLETE_CONFIRMATION.EDIT_MODAL.CANCEL_LABEL,
            }}
          />
        )}
        {isConfirmationModalOpen && (
          <Confirmation
            confirmModalProps={{
              question: COMPLETE_CONFIRMATION.CONFIRMATION_MODAL.QUESTION,
              onConfirm: handleCompeteConfirm,
              onClose: handleModalClose,
              title: COMPLETE_CONFIRMATION.CONFIRMATION_MODAL.TITLE,
              confirmLabel:
                COMPLETE_CONFIRMATION.CONFIRMATION_MODAL.BUTTON_LABEL,
            }}
          />
        )}
        {isRemoveModalOpen && (
          <Confirmation
            confirmModalProps={{
              question: `${VEHICLES_DELETE_CONFIRMATION.CONFIRMATION_MODAL.QUESTION} ${selectedCar?.name}?`,
              onConfirm: handleRemoveConfirm,
              onClose: handleRemoveModalClose,
              title: `${VEHICLES.DELETE} ${selectedCar?.name} ${VEHICLES.MODEL}`,
            }}
          />
        )}
        <div className={styles['vehicle-block']}>
          <div className={styles['vehicle-header']}>
            <div className={styles['vehicle-title']}>
              <Typography name="H5">{TABLE_INFO.TITLE}</Typography>
              <Typography name="Button2">{TABLE_INFO.SUBTITLE}</Typography>
            </div>
            <div className={styles['complete-btn']}>
              <OriginButton
                onClick={handleModalShow}
                label={TABLE_INFO.BUTTON_LABEL}
                disabled={!formattedBodyItems?.length}
                variant="green"
                size="large"
              />
            </div>
          </div>
          <div className={styles.table}>
            <Table
              headItems={formattedHeadItems}
              bodyItems={formattedBodyItems}
              initItemsWidth={['53', '260', '125', '90', '130', '195']}
            />
          </div>
        </div>
      </>
    )
  }
)
