import { SelectValue } from 'types/form'
import {
  Multiselect,
  Typography,
  RadioButton,
  Input,
  LazyImage,
  Button,
} from '@frontend/design_system'
import { Row } from '../../../Row'
import { ValueType } from 'ui/molecules/Form/types'
import { RENTAL_INCLUDES_FORM_VALUES } from 'texts/termsAndConditions'
import {
  CAR_TYPES_OPTIONS,
  DISTANCE_UNIT_NAMES,
  DISTANCE_UNIT_NAMES_OPTIONS,
} from 'constants/termsAndConditions'
import plus from 'assets/icons/blue700/blue700_plus_20x20.webp'
import plusDisabled from 'assets/icons/blueSecondary/blueSecondary_plusDisabled_20x20.webp'
import trash from 'assets/icons/red600/red600_trash_20x20.webp'
import { ExceptionError, ExceptionErrors, ExceptionValues } from '../../types'
import styles from './styles.module.scss'

interface IExceptionProps {
  values: ExceptionValues[]
  onChange: (value: ValueType) => void
  error: ExceptionErrors
  onBlur: VoidFunction
  onChangeErrors: (index: number, key: keyof ExceptionError) => void
}

export const Exception = ({
  values,
  onChange,
  onBlur,
  error = [],
  onChangeErrors,
}: IExceptionProps) => {
  const handleChange = (id: number, newValues: Partial<ExceptionValues>) => {
    onChange(
      values.map((value) =>
        value.id === id
          ? {
              ...value,
              ...newValues,
            }
          : value
      )
    )
  }

  const onAddRow = () => {
    onChange([
      ...values,
      {
        id: Date.now(),
        distance: undefined,
        distanceUnit: 0,
        carTypes: [],
        durationDays: undefined,
      },
    ])
    onBlur()
  }

  const onDeleteRow = (id: number) => () => {
    onChange(values.filter((value) => value.id !== id))
    onBlur()
  }

  return (
    <>
      {values.map(
        ({ carTypes, durationDays, distanceUnit, distance, id }, index) => {
          const isButtonDisabled = carTypes?.length === CAR_TYPES_OPTIONS.length

          return (
            <div key={id}>
              <Multiselect
                value={carTypes?.map(({ value, label }) => ({
                  value,
                  label,
                }))}
                placeholder={RENTAL_INCLUDES_FORM_VALUES.CAR_TYPES.PLACEHOLDER}
                error={Array.isArray(error) ? error[index]?.carTypes : error}
                isRequired
                items={CAR_TYPES_OPTIONS}
                onChange={(value: SelectValue[]) => {
                  handleChange(id, {
                    carTypes: value,
                  })
                  onChangeErrors(index, 'carTypes')
                }}
                label={RENTAL_INCLUDES_FORM_VALUES.CAR_TYPES.LABEL}
              />
              <Row>
                <div className={styles['distance-wrapper']}>
                  <Input
                    value={distance}
                    size="large"
                    error={
                      Array.isArray(error) ? error[index]?.distance : error
                    }
                    spaceForError="auto"
                    onChange={(value: string) => {
                      handleChange(id, { distance: value })
                      onChangeErrors(index, 'distance')
                    }}
                    label={RENTAL_INCLUDES_FORM_VALUES.DISTANCE.LABEL}
                    isRequired
                    type="integer"
                  />
                </div>
                <div>
                  <Typography
                    name="body2WMedium"
                    Tag="p"
                    className={styles['radio-button-label']}
                    markColor="red600Master"
                  >
                    {RENTAL_INCLUDES_FORM_VALUES.UNIT.LABEL} <mark>*</mark>
                  </Typography>
                  <div className={styles['radio-buttons']}>
                    {DISTANCE_UNIT_NAMES_OPTIONS.map(({ label, value }) => (
                      <RadioButton
                        labelTypographyName="Button2"
                        key={value}
                        id={value}
                        prefix={`${id}-distance-unit`}
                        label={label}
                        onChange={(value: DISTANCE_UNIT_NAMES) =>
                          handleChange(id, { distanceUnit: value })
                        }
                        checked={value === distanceUnit}
                      />
                    ))}
                  </div>
                </div>
              </Row>
              <div className={styles['row-with-actions']}>
                <div className={styles.duration}>
                  <Input
                    value={durationDays}
                    placeholder={RENTAL_INCLUDES_FORM_VALUES.PERIOD.PLACEHOLDER}
                    size="large"
                    onChange={(value: string) => {
                      handleChange(id, { durationDays: value })
                      onChangeErrors(index, 'duration')
                    }}
                    label={RENTAL_INCLUDES_FORM_VALUES.PERIOD.LABEL}
                    isRequired
                    type="integer"
                    spaceForError="auto"
                    error={
                      Array.isArray(error) ? error[index]?.duration : error
                    }
                  />
                </div>
                {values.length !== 1 && (
                  <div className={styles.action}>
                    <Button
                      label={<LazyImage src={trash} width={20} height={20} />}
                      variant="red"
                      onClick={onDeleteRow(id)}
                      size="large"
                    />
                  </div>
                )}
                {index === values.length - 1 && (
                  <div className={styles.action}>
                    <Button
                      label={
                        <LazyImage
                          src={isButtonDisabled ? plusDisabled : plus}
                          width={20}
                          height={20}
                        />
                      }
                      variant="secondary-border"
                      disabled={isButtonDisabled}
                      onClick={onAddRow}
                      size="large"
                    />
                  </div>
                )}
              </div>
            </div>
          )
        }
      )}
    </>
  )
}
