import { Input, Select } from '@frontend/design_system'
import { LOCATION_ADDING_FORM_VALUES } from 'texts/locationDetails'
import { StateFieldProps } from './types'
import { ItemType } from 'ui/atoms/Select/types'

export const StateField = ({
  states,
  value,
  onChange,
  error,
  isStateWithDropdown,
  handleStateChange,
}: StateFieldProps) =>
  isStateWithDropdown ? (
    <Select
      size="large"
      placeholder={LOCATION_ADDING_FORM_VALUES.STATE.SELECT_PLACEHOLDER}
      onChange={(el: string, item: ItemType) => {
        onChange(el, item)
        handleStateChange(item)
      }}
      label={LOCATION_ADDING_FORM_VALUES.STATE.LABEL}
      items={states}
      error={error}
      selectedValue={value}
      isRequired
      search={{
        placeholder: LOCATION_ADDING_FORM_VALUES.CITY.SEARCH_PLACEHOLDER,
      }}
    />
  ) : (
    <Input
      value={value}
      placeholder={LOCATION_ADDING_FORM_VALUES.STATE.PLACEHOLDER}
      size="large"
      error={error}
      spaceForError="auto"
      onChange={onChange}
      label={LOCATION_ADDING_FORM_VALUES.STATE.LABEL}
    />
  )
