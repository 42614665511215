import { Form, FormItem, Input, Typography } from '@frontend/design_system'
import {
  ADDED_SUCCESS,
  DRIVER_REQUIREMENTS,
  DRIVER_REQUIREMENTS_CONTENT,
  DRIVER_REQUIREMENTS_FORM_VALUES,
  UPDATED_SUCCESS,
} from 'texts/termsAndConditions'
import { Fragment, useMemo } from 'react'
import { Block } from '../Block'
import { ActionButtons } from '../ActionButtons'
import { FormChildren, FormItemChildren } from 'types/form'
import { IFormValues } from './types'
import { LICENSE_YEARS_RULES } from './constants'
import styles from './styles.module.scss'
import { useApiRequest } from 'hooks/useApiRequest'
import { termsApi } from 'api'
import { IContentProps } from 'modules/TermsAndConditions/types'
import { TERM_CONDITIONS_TYPES } from 'constants/termsAndConditions'
import { useDispatch } from 'react-redux'
import { setNotificationMessage } from 'redux/notifications/slice'
import { useCompanyId } from 'hooks/useCompanyId'
import { SAVE } from 'texts/uiTexts'
import { ADD } from 'texts/common'

export const DriverRequirements = ({ reload, data }: IContentProps) => {
  const dispatch = useDispatch()
  const companyId = useCompanyId()
  const newTermConditionRequest = useApiRequest(
    (body) => termsApi.newTermCondition(body),
    undefined,
    true,
    undefined,
    false
  )
  const updateTermConditionRequest = useApiRequest(
    (body) => termsApi.updateTermCondition(body),
    undefined,
    true,
    undefined,
    false
  )

  const initValues = useMemo(() => {
    const initData: IFormValues = {
      driversLicenseYear: undefined,
    }

    if (data) {
      initData.driversLicenseYear = data.driversLicenseYear
    }

    return initData
  }, [data])

  const onSubmit = async (values: IFormValues, validate: boolean) => {
    if (validate) {
      const body = {
        termConditionTypeId: TERM_CONDITIONS_TYPES.DriverRequirements,
        companyId,
        driversLicenseYear: Number(values.driversLicenseYear),
      }

      if (data) {
        await updateTermConditionRequest.apiRequest({
          ...body,
          termConditionSettingId: data.id,
        })
        dispatch(
          setNotificationMessage({
            notificationMessage: UPDATED_SUCCESS,
          })
        )
      } else {
        await newTermConditionRequest.apiRequest(body)
        dispatch(
          setNotificationMessage({
            notificationMessage: ADDED_SUCCESS,
          })
        )
      }

      reload()
    }
  }

  return (
    <Block title={DRIVER_REQUIREMENTS}>
      <Form initValues={initValues} onSubmit={onSubmit} key={data?.id}>
        {({ isDirty }: FormChildren) => (
          <>
            <FormItem
              id={DRIVER_REQUIREMENTS_FORM_VALUES.LICENSE_YEARS.ID}
              rules={LICENSE_YEARS_RULES}
            >
              {({ value, error, onChange }: FormItemChildren) => (
                <Input
                  value={value}
                  size="large"
                  error={error}
                  spaceForError="auto"
                  onChange={onChange}
                  label={DRIVER_REQUIREMENTS_FORM_VALUES.LICENSE_YEARS.LABEL}
                  isRequired
                  type="integer"
                  placeholder={
                    DRIVER_REQUIREMENTS_FORM_VALUES.LICENSE_YEARS.PLACEHOLDER
                  }
                />
              )}
            </FormItem>
            <Typography name="body1WBold" Tag="h4">
              {DRIVER_REQUIREMENTS_CONTENT.TITLE}
            </Typography>
            {DRIVER_REQUIREMENTS_CONTENT.LIST.map(
              ({ TITLE, DESCRIPTION }, index) => (
                <Fragment key={index}>
                  <Typography
                    name="body1WBold"
                    Tag="h5"
                    className={styles['requirements-title']}
                  >
                    {TITLE}
                  </Typography>
                  <Typography name="body2WMedium" Tag="p">
                    {DESCRIPTION}
                  </Typography>
                </Fragment>
              )
            )}
            <ActionButtons
              isOffset
              submitLabel={data ? SAVE : ADD}
              disabled={!isDirty}
              loading={
                newTermConditionRequest.loading ||
                updateTermConditionRequest.loading
              }
            />
          </>
        )}
      </Form>
    </Block>
  )
}
