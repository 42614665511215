import {
  DAILY_RANGE_TYPES,
  PRICE_LIST_DAILY_RANGE,
} from 'texts/priceListDetails'
import { Input } from 'ui/atoms/Input'
import { INFINITY } from '../../constants'
import { Select } from '@frontend/design_system'
import { TrashIconWithBg } from 'ui/icons'
import { DailyRangeProps } from './types'
import { FleetApiTypes } from 'api'
import { RangeType } from '../../types'
import { allCharactersExceptNumbers } from 'constants/regex'
import styles from './styles.module.scss'

export const DailyRange = <T extends RangeType>({
  handleRemoveRange,
  index,
  handleChange,
  range,
  readOnly,
}: DailyRangeProps<T>) => {
  const onChange = (value: string, key: keyof FleetApiTypes.DailyRange) => {
    let newValue = value
    if (key !== 'rangeType') {
      newValue = value.replace(allCharactersExceptNumbers, '')
    }
    if (key === 'rangeEnd' && value.endsWith(INFINITY)) {
      newValue = INFINITY
    }

    handleChange(index, { ...range, [key]: newValue })
  }

  const { rangeStart, rangeEnd, rangeType } = range as FleetApiTypes.DailyRange

  return (
    <div className={styles.range}>
      <div className={styles.input}>
        <Input
          size="large"
          value={rangeStart}
          onChange={(value) => onChange(value, 'rangeStart')}
          spaceForError="auto"
          disabled={readOnly}
          label={PRICE_LIST_DAILY_RANGE.START}
        />
      </div>
      <div className={styles.input}>
        <Input
          size="large"
          value={rangeEnd}
          onChange={(value) => onChange(value, 'rangeEnd')}
          spaceForError="auto"
          disabled={readOnly}
          label={PRICE_LIST_DAILY_RANGE.END}
        />
      </div>
      <div className={styles.input}>
        <Select
          selectedValue={rangeType}
          placeholder=""
          size="large"
          disabled={readOnly}
          items={DAILY_RANGE_TYPES}
          onChange={(value: string) => onChange(value, 'rangeType')}
          label={PRICE_LIST_DAILY_RANGE.TYPE}
        />
      </div>
      {!readOnly && (
        <TrashIconWithBg
          color="blue700"
          size="large"
          className={styles.icon}
          onClick={handleRemoveRange}
        />
      )}
    </div>
  )
}
