import { DriverAgeRangeType } from 'api/fleet/types'
import { RADIO_BUTTON_LABELS } from './equipmentDetails'
import {
  CROSS_BORDER_POLICY_TYPES,
  FUEL_POLICY_TYPES,
  INSURANCE,
  MILEAGE_TYPES,
} from 'constants/termsAndConditions'

export const RENTAL_INCLUDES = 'Your rental includes'
export const RENTAL_EXCLUDES = 'Your rental excludes'
export const DRIVER_REQUIREMENTS =
  "Driving age and driver's license requirements"
export const FUEL_POLICY = 'Fuel policy'
export const CROSS_BORDER_POLICY = 'Cross-border policy'
export const INSURANCE_INCLUDED = 'Insurance included'
export const DRIVER_CREDIT = 'Primary driver credit card pre-authorization'
export const EXCESS_DEPOSIT = 'Excess / deposit'
export const EXCEPTIONS = 'Exceptions'
export const RENTAL_INCLUDES_DEFAULT_MESSAGE =
  'By default, all car rentals come with unlimited mileage. If you want to set mileage limitations for certain car type(s), please '
export const EXCEPTIONS_BUTTON = 'go to Exceptions.'
export const RENTAL_INCLUDES_LIMIT =
  'Please note that the limitations apply only to the car type(s) you have selected. For all other types, the mileage remains unlimited.'
export const MILEAGE = 'Mileage'
export const HISTORY_LIST_ACTION = 'View / Edit'
export const HISTORY_LIST_TITLE = "T&C by customer's country of residence"
export const INSURANCE_TOOLTIP =
  'Collision Damage Waiver, Theft Protection, Third Party Liability etc.'
export const ADDITIONAL_EQUIPMENT_TOOLTIP =
  'Child seat, navigation system (GPS) requested at the time of booking or purchased locally, extra services (e.g. additional driver, out of hours fees, one way rentals) etc.'
export const INSURANCE_INCLUDED_NOTE =
  'Please note that if you select Excluded, the Insurance section will not be shown to users.'
export const INSURANCE_EXLUDED_NOTE =
  'Please note, if insurance is not included, this block must be removed.'
export const INSURANCE_INCLUDED_DESCRIPTION =
  'Most car rentals come with Collision Damage Waiver (CDW) and Theft Protection (TP), limiting your liability to the excess – the amount of money the car rental company will charge you if the car is damaged or stolen. CDW covers damage to the car’s body but usually excludes the windscreen, tires, and locks, while TP ensures you pay only the deductible in case of theft. Personal belongings are not covered by TP.'
export const DRIVER_CREDIT_DESCRIPTION = `
  <p>At time of pick up you will be required to leave a deposit to cover the value of the insurance excess. Usually it takes the form of an amount (minimum: excess+fuel+vat) being blocked on an international credit card in the name of the primary driver (cash deposits, maestro, switch visa electron and debit cards are not accepted).</p>
  <p>This company does not accept prepaid, reloadable or virtual credit cards as well as cards not having embossed (raised) characters on the front.</p>
  <p>Please follow the link and enter first 6 digits of your card number to check the type of your card: <a href="http://www.binbase.com/search.html" target="_blank">http://www.binbase.com/search.html</a></p>
  <p>The blocked amount will be returned in full at the end of the rental provided the vehicle is returned in the same condition as rented.</p>
`
export const CROSS_BORDER_POLICY_DESCRIPTION =
  'The car is supplied with a full tank of fuel. It should full on return or refueling charges will be applied.'

export const COUNTRY_DUPLICATES_ERROR = 'Each country can only be listed once'
export const MIN_MAX_AGE_ERROR = 'Min age should be less than max age'
export const YOUNG_DRIVER_SURCHARGE = 'Young Driver Surcharge'
export const YOUNG_DRIVER_SURCHARGE_TOOLTIP =
  'The data is based on the driver age ranges set in the Reservation section for your locations.'
export const GO_TO_LOCATION = 'Go to Locations'
export const COMPANY_CLASS = 'Company class'
export const EXCESS = 'Excess'
export const DEPOSIT = 'Deposit'
export const SIPP = 'SIPP'

export const DRIVER_REQUIREMENTS_CONTENT = {
  TITLE: 'Driver Requirements',
  LIST: [
    {
      TITLE: "Driver's license",
      DESCRIPTION:
        "When renting a car, you must provide a driver's license valid at the start of the rental and throughout the rental period. If you are traveling outside your home country, you may need an international driver's license. Please note that it is only valid when presented with the original driver's license.",
    },
    {
      TITLE: 'Young driver surcharge',
      DESCRIPTION:
        'In car rentals, a young driver is usually someone below 25 years old. Young drivers are subject to an extra surcharge and must be at least 21 in most countries.',
    },
    {
      TITLE: 'Forms of identification',
      DESCRIPTION:
        'Commonly accepted forms of identification required to rent a car include passport and identity card.',
    },
  ],
}

export const ADDED_SUCCESS = 'Your T&C have been successfully added'
export const UPDATED_SUCCESS = 'Your T&C have been successfully updated'
export const DELETED_SUCCESS = 'Your T&C have been successfully deleted'
export const DELETE_MODAL_TITLE =
  "Delete T&C by customer's country of residence"
export const DELETE_MODAL_DESCRIPTION =
  "Are you sure that you want to delete this T&C by customer's country of residence?"
export const CAR_TYPE_DUPLICATE_MESSAGE =
  'Each Car type can only be listed once.'

export const TERMS_AND_CONDITIONS_FORM_VALUES = {
  COR: {
    ID: 'cor',
    LABEL: "Customer's country of residence",
    PLACEHOLDER: "Select customer's country of residence",
    TOOLTIP:
      'Customers see Terms & Conditions set for the countries of residence specified in this section.',
    REQUIRED_MESSAGE: "Please select customer's country of residence.",
  },
}

export const RENTAL_INCLUDES_FORM_VALUES: {
  [key: string]: {
    ID: string | MILEAGE_TYPES
    LABEL: string
    PLACEHOLDER?: string
    REQUIRED_MESSAGE?: string
    VALIDATION_MESSAGE?: string
  }
} = {
  LOCAL_TAXES: {
    ID: 'localTaxes',
    LABEL: 'Local taxes',
  },
  INSURANCE: {
    ID: 'insurance',
    LABEL: 'Insurance',
  },
  AIRPORT_SURCHARGE: {
    ID: 'airportSurcharge',
    LABEL: 'Airport surcharge',
  },
  ROAD_ASSISTANCE: {
    ID: 'roadAssistance',
    LABEL: '24H Road Assistance',
  },
  MILEAGE: {
    LABEL: 'Mileage type',
    ID: 'mileageType',
  },
  DETAILS: {
    LABEL: 'Details',
    ID: 'details',
  },
  UNLIMITED: {
    ID: MILEAGE_TYPES.Unlimited,
    LABEL: 'Unlimited',
  },
  LIMITED: {
    ID: MILEAGE_TYPES.Limited,
    LABEL: 'Limited',
  },
  CAR_TYPES: {
    ID: 'carTypes',
    LABEL: 'Car type(s)',
    PLACEHOLDER: 'Select a car type',
    REQUIRED_MESSAGE: 'Please select a car type.',
  },
  PICKUP_COUNTRY: {
    ID: 'pickupCountry',
    LABEL: 'Pick-up country',
  },
  DISTANCE: {
    ID: 'distance',
    LABEL: 'Distance limit',
    REQUIRED_MESSAGE: 'Please enter a distance.',
    VALIDATION_MESSAGE:
      'Minimum distance limit value must be greater or equal to 1.',
  },
  UNIT: {
    ID: 'unit',
    LABEL: 'Unit',
    REQUIRED_MESSAGE: 'Please select a distance unit.',
  },
  PERIOD: {
    ID: 'period',
    LABEL: 'Duration limit, day(s)',
    PLACEHOLDER: 'Enter the number of days: e.g. 10',
    REQUIRED_MESSAGE: 'Please enter the duration.',
    VALIDATION_MESSAGE:
      'Minimum number of days must be greater or equal to 1 day.',
  },
}

export const RENTAL_EXCLUDES_FORM_VALUES = {
  FINES_FEES_PARKING: {
    ID: 'fines',
    LABEL: 'Fines, Toll Fees & Parking charges',
  },
  ADDITIONAL_EQUIPMENT: {
    ID: 'additionalEquipment',
    LABEL: 'Additional equipment',
  },
}

export const FUEL_POLICY_FORM_VALUES = {
  FUEL: {
    ID: 'fuelPolicy',
  },
  FULL_TO_FULL: {
    ID: FUEL_POLICY_TYPES.FullToFull,
    LABEL: 'Full to Full',
    DESCRIPTION:
      'The car is supplied with a full tank of fuel. It should be full on return or refueling charges will be applied.',
  },
  SAME_TO_SAME: {
    ID: FUEL_POLICY_TYPES.FullToEmpty,
    LABEL: 'Same to same',
    DESCRIPTION:
      'Same to Same means that if you pick up the car with a fixed amount of fuel in the tank, you should also return the car with the same amount of fuel.',
  },
}

export const INSURANCE_INCLUDED_FORM_VALUES = {
  INSURANCE: {
    ID: 'insurance',
  },
  INCLUDED: {
    ID: INSURANCE.Include,
    LABEL: 'Included',
  },
  EXCLUDED: {
    ID: INSURANCE.Exclude,
    LABEL: 'Excluded',
  },
}

export const CROSS_BORDER_POLICY_FORM_VALUES = {
  ALLOWED: {
    ID: 'crossBorderPolicy',
  },
  ALLOWED_TO: {
    ID: CROSS_BORDER_POLICY_TYPES.AllowedTo,
    LABEL: 'Allowed to',
  },
  NOT_ALLOWED: {
    ID: CROSS_BORDER_POLICY_TYPES.NotAllowed,
    LABEL: 'Not allowed',
  },
  COUNTRIES: {
    ID: 'countries',
  },
  COUNTRY_LIST: {
    ID: 'countryList',
    LABEL: 'Country',
    PLACEHOLDER: 'Add country',
  },
  PRICE: {
    ID: 'price',
    LABEL: 'Cross-border fee',
    PLACEHOLDER: 'Add fee',
  },
}

export const DRIVER_REQUIREMENTS_FORM_VALUES = {
  MIN_AGE: {
    ID: 'minDrivingAge',
    LABEL: 'MIN driving age',
    PLACEHOLDER: 'Enter age',
  },
  MAX_AGE: {
    ID: 'maxDrivingAge',
    LABEL: 'MAX driving age',
    PLACEHOLDER: 'Enter age',
  },
  LICENSE_YEARS: {
    ID: 'driversLicenseYear',
    LABEL: "Driver's license must be held for at least year(s)",
    PLACEHOLDER: 'Enter the number of years',
    REQUIRED_MESSAGE: 'Please enter the number of years equal to 1 or greater.',
    VALIDATION_MESSAGE: 'The number of years must be equal to 1 or greater.',
  },
  LOCATION: {
    ID: 'locationContractId',
    LABEL: 'Location',
    PLACEHOLDER: 'Select location',
    SEARCH_PLACEHOLDER: 'Search for location',
  },
  FROM: {
    ID: 'from',
    LABEL: 'From',
  },
  TO: {
    ID: 'to',
    LABEL: 'To',
  },
  FEE: {
    ID: 'fee',
    LABEL: 'Fee',
  },
  MAX_PRICE: {
    ID: 'maxPrice',
    LABEL: 'Max price',
  },
  PER_RENTAL: {
    ID: DriverAgeRangeType.PerRental,
    LABEL: RADIO_BUTTON_LABELS.RENT,
  },
  PER_DAY: {
    ID: DriverAgeRangeType.PerDay,
    LABEL: RADIO_BUTTON_LABELS.DAY,
  },
}
