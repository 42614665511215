import { useEffect, useMemo, useRef, useState } from 'react'
import { Typography } from '@frontend/design_system'
import { joinClasses } from 'utils/joinClasses'
import { Input } from 'ui/atoms/Input'
import { boldMatch, getMatch } from 'utils/match'
import { ListProps } from './types'
import { CheckedIcon } from 'ui/icons'
import { NOT_FOUND, SEARCH_ITEMS_COUNT } from './constants'
import { HtmlTypography } from 'ui/atoms/HtmlTypography'
import styles from './styles.module.scss'

const ITEM_ID = 'item'

export const SelectList = ({
  items,
  selectedValue,
  onChange,
  containerRef,
  search,
  notFoundTitle,
  inputSize,
  dataTestId,
}: ListProps) => {
  const [searchValue, setSearchValue] = useState('')
  const searchRef = useRef<HTMLDivElement | null>(null)
  const listRef = useRef<HTMLUListElement | null>(null)

  useEffect(() => {
    const element = document.getElementById(`${ITEM_ID}-${selectedValue}`)

    if (element && containerRef.current) {
      const { top: containerTop } = containerRef.current.getBoundingClientRect()
      const { top, height } = element.getBoundingClientRect()

      const searchHeight =
        searchRef.current?.getBoundingClientRect().height || 0

      if (listRef.current) {
        listRef.current.scrollTo({
          top: top - containerTop - height - searchHeight,
        })
      }
    }
  }, [])

  const filteredItems = useMemo(
    () =>
      searchValue
        ? items.filter(({ label: itemLabel }) =>
            getMatch(searchValue, itemLabel)
          )
        : items,
    [searchValue, items]
  )

  return (
    <div data-testid={dataTestId}>
      {search && (search.showSearch || items.length > SEARCH_ITEMS_COUNT) && (
        <div className={styles['select-list-search']} ref={searchRef}>
          <Input
            spaceForError="none"
            typographyName="Button2"
            iconLeft="SearchIcon"
            iconSize="small"
            onChange={setSearchValue}
            value={searchValue}
            size={inputSize || 'large'}
            placeholder={search.placeholder}
            caretColorVariant="secondary"
          />
        </div>
      )}
      {filteredItems.length ? (
        <ul className={styles['select-list']} ref={listRef}>
          {filteredItems.map((item, i) => {
            const key = i
            const isSelected = item.value === selectedValue
            const handleChange = () => onChange(item.value, item)

            return (
              <Typography
                key={key}
                id={`${ITEM_ID}-${item.value}`}
                name="Button2"
                className={joinClasses(styles['select-list-item'], [
                  styles.selected,
                  isSelected,
                ])}
                Tag="li"
                onClick={handleChange}
                dataTestId={`${dataTestId}-item-${i}`}
              >
                <div className={styles['select-list-item-content']}>
                  {item.renderLabel?.(item.label) || (
                    <HtmlTypography
                      html={boldMatch(searchValue, item.label)}
                      name="Button2"
                      variant="primary"
                    />
                  )}
                  {isSelected && (
                    <CheckedIcon
                      color="green700Master"
                      className={styles['select-list-icon']}
                      size="small"
                    />
                  )}
                </div>
              </Typography>
            )
          })}
        </ul>
      ) : (
        <div className={styles['not-found']}>
          <Typography name="Button2" color="blueSecondary">
            {notFoundTitle || NOT_FOUND}
          </Typography>
        </div>
      )}
    </div>
  )
}
