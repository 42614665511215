import { useContext } from 'react'
import { LOCATION_TABS } from 'texts/locationDetails'
import { ImportantIcon } from 'ui/icons'
import { Tabs, TabPane } from '@frontend/design_system'
import {
  CONTACT_FORM_FIELDS,
  RESERVATION_FORM_FIELDS,
  WAITING_HOURS_FORM_FIELDS,
} from '../../constants'
import { FormContext } from 'ui/molecules/Form/Form'
import { ContextType } from 'ui/molecules/Form/types'
import { LeftSideProps } from './types'
import styles from './styles.module.scss'

export const LeftSide = ({
  activeKey,
  changeActiveKey,
  tabs,
  driverAgeRangeError,
  workingHoursError,
}: LeftSideProps) => {
  const { errors } = useContext<ContextType>(FormContext)

  const checkTabError = (fields: { [key: string]: string }) => {
    const formErrors = Object.entries(errors).map(([key, value]) => ({
      id: key,
      value,
    }))

    const tabErrors = formErrors.filter((item) => item.value && fields[item.id])
    return !!tabErrors.length
  }

  const tabErrors = {
    [LOCATION_TABS.INFORMATION]: checkTabError(CONTACT_FORM_FIELDS),
    [LOCATION_TABS.WORKING_HOURS]: workingHoursError,
    [LOCATION_TABS.RESERVATION]:
      checkTabError(RESERVATION_FORM_FIELDS) || !!driverAgeRangeError,
    [LOCATION_TABS.WAITING_HOURS]: checkTabError(WAITING_HOURS_FORM_FIELDS),
  }

  const renderTabButton = (element: React.ReactNode, withError: boolean) => {
    if (withError) {
      return (
        <div className={styles['tab-children-error']}>
          {element} <ImportantIcon size="medium" />
        </div>
      )
    }
    return element
  }

  const renderTab = (element: React.ReactNode, tabKey: string) => {
    if (tabKey === LOCATION_TABS.INFORMATION) {
      return renderTabButton(element, tabErrors[tabKey])
    }

    if (tabKey === LOCATION_TABS.RESERVATION) {
      return renderTabButton(element, tabErrors[tabKey])
    }

    if (tabKey === LOCATION_TABS.WORKING_HOURS) {
      return renderTabButton(element, tabErrors[tabKey])
    }

    if (tabKey === LOCATION_TABS.WAITING_HOURS) {
      return renderTabButton(element, tabErrors[tabKey])
    }

    return element
  }

  return (
    <div className={styles.container}>
      <Tabs activeKey={activeKey} onChange={changeActiveKey} type="marketplace">
        {tabs.map((tab) => (
          <TabPane
            id={tab.tab}
            key={tab.key}
            tab={renderTab(tab.tab, tab.key)}
            isError={tabErrors[tab.tab]}
          >
            <div className={styles['tab-children']}>{tab.children}</div>
          </TabPane>
        ))}
      </Tabs>
    </div>
  )
}
