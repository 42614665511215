export const STATUS_BADGE = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
}

export const DATEPICKER_PLACEHOLDER = 'Select date'

export const FILTER = {
  SELECT_ALL: 'all',
  SORT_ASCENDING: 'Sort Ascending',
  SORT_DESCENDING: 'Sort Descending',
  CLEAR_SORTING: 'Clear sorting',
}

export const PAGINATION_DROPDOWN_LABEL = 'Show: '

export const WEEKDAYS = {
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
  SUNDAY: 'Sunday',
}

export const WEEKDAYS_SHORT = {
  [WEEKDAYS.MONDAY]: 'Mon',
  [WEEKDAYS.TUESDAY]: 'Tue',
  [WEEKDAYS.WEDNESDAY]: 'Wed',
  [WEEKDAYS.THURSDAY]: 'Thu',
  [WEEKDAYS.FRIDAY]: 'Fri',
  [WEEKDAYS.SATURDAY]: 'Sat',
  [WEEKDAYS.SUNDAY]: 'Sun',
}

export const PASSWORD_SCALE = {
  RULES: {
    MIN_CHARACTERS: 'At least 8 characters, no spaces',
    UPPER_AND_LOWER_CASE: 'Upper & lowercase Latin letters',
    ONE_NUMBER: 'At least one number',
  },
  LABEL: 'Your password must have',
  WEAK: 'Weak',
  MEDIUM: 'Medium',
  STRONG: 'Strong',
}

export const CANCEL = 'Cancel'
export const SAVE = 'Save'
export const CONFIRM = 'Confirm'
export const SEARCH = 'Search'
export const TOTAL = 'Total'

export const AUTOCOMPLETE_LABEL = 'out of'

export const PHONE_NUMBER_LABELS = {
  OF: 'of',
  SEARCH: 'Search for a country',
  SAVE: 'save',
}

export const UPLOAD_FILE_SIZE_ERROR =
  'The picture exceeds maximum file size limit: 30MB.'

export const UPLOAD_FILE_DIMENSION_ERROR = 'Image must be 100x40 px.'

export const NOT_FOUND = {
  TITLE: 'Page not found',
  DESCRIPTION: "Sorry, we couldn't find the page you’re looking for.",
}

export const LATIN_LETTER_RULE = 'Please use Latin characters only.'

export const NO_DATA = 'No data'

export const DIGITS_WITH_DOT_RULE = "Please use digits and '.' symbol."

export const DIGITS_RULE = 'Please use digits.'

export const TOGGLE_LABELS = { active: 'Active', inactive: 'Inactive' }

export const SEQUENCE_NUMBER = 'No.'

export const ITEM_NOT_FOUND = 'Not found'
