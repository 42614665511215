import { useState } from 'react'
import { Typography } from '@frontend/design_system'
import { EllipsisHorizontalIcon } from 'ui/icons'
import { ActionItemsProps } from './types'
import { joinClasses } from 'utils/joinClasses'
import { useOutsideClick } from 'ui/hooks/useOutsideClick'
import styles from './styles.module.scss'

export const ActionItems = ({
  items,
  listItemWidth,
  waitPreparation,
}: ActionItemsProps) => {
  const [isListItemOpen, setListItemOpen] = useState(false)
  const [actionItemsElement, setActionItemsElement] =
    useState<HTMLDivElement | null>(null)

  const handleClick = async (e: React.MouseEvent) => {
    e.stopPropagation()

    await waitPreparation()
    setListItemOpen((isListItemOpen) => !isListItemOpen)
  }

  const handleClose = () => setListItemOpen(false)

  useOutsideClick(actionItemsElement, handleClose)

  return (
    <div
      ref={setActionItemsElement}
      className={styles['action-items']}
      onClick={handleClick}
      role="button"
      data-testid="action-items-wrapper"
    >
      <EllipsisHorizontalIcon size="large" />
      {isListItemOpen && !!items.length && (
        <div className={styles['list-item']} style={{ width: listItemWidth }}>
          {items.map(({ value, onClick, isDisabled, path }, index) => (
            <Typography
              key={index}
              Tag="a"
              className={joinClasses(styles.item, [
                styles.disabled,
                isDisabled,
              ])}
              href={isDisabled ? undefined : path}
              name="Button2"
              dataTestId="action-item"
              color={isDisabled ? 'grey500' : 'blackMaster'}
              onClick={(e: React.MouseEvent) => {
                e.preventDefault()
                return !isDisabled && onClick(path)
              }}
            >
              {value}
            </Typography>
          ))}
        </div>
      )}
    </div>
  )
}
