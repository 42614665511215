import { TERM_CONDITIONS_TYPES } from 'constants/termsAndConditions'
import { URLS } from 'constants/urls'
import {
  CROSS_BORDER_POLICY,
  DRIVER_CREDIT,
  DRIVER_REQUIREMENTS,
  EXCESS_DEPOSIT,
  FUEL_POLICY,
  INSURANCE_INCLUDED,
  RENTAL_EXCLUDES,
  RENTAL_INCLUDES,
} from 'texts/termsAndConditions'

export const NAVIGATION_ITEMS = [
  {
    url: URLS.RENTAL_INCLUDES,
    label: RENTAL_INCLUDES,
  },
  {
    url: URLS.RENTAL_EXCLUDES,
    label: RENTAL_EXCLUDES,
  },
  {
    url: URLS.DRIVER_REQUIREMENTS,
    label: DRIVER_REQUIREMENTS,
  },
  {
    url: URLS.FUEL_POLICY,
    label: FUEL_POLICY,
  },
  {
    url: URLS.CROSS_BORDER_POLICY,
    label: CROSS_BORDER_POLICY,
  },
  {
    url: URLS.INSURANCE_INCLUDED,
    label: INSURANCE_INCLUDED,
  },
  {
    url: URLS.DRIVER_CREDIT,
    label: DRIVER_CREDIT,
  },
  {
    url: URLS.EXCESS_DEPOSIT,
    label: EXCESS_DEPOSIT,
  },
]

export const TERMS_WITHOUTH_HISTORY = [
  TERM_CONDITIONS_TYPES.FuelPolicy,
  TERM_CONDITIONS_TYPES.DriverRequirements,
  TERM_CONDITIONS_TYPES.CrossBorderPolicy,
  TERM_CONDITIONS_TYPES.DriverCreditCard,
]
