import {
  CAR_TYPE_DUPLICATE_MESSAGE,
  RENTAL_INCLUDES_FORM_VALUES,
} from 'texts/termsAndConditions'
import { ExceptionErrors, ExceptionValues, IFormValues } from '../types'
import { useState } from 'react'

export const useCheckExceptionErrors = () => {
  const [exceptionsErrors, setExceptionsErrors] = useState<ExceptionErrors>([])

  const checkDuplicates = (values: ExceptionValues[]) => {
    const allCarTypes: { [key: string]: number[] } = {}
    const errors: string[] = Array(values.length).fill('')

    for (let i = 0; i < values.length; i++) {
      const { carTypes } = values[i]
      for (const { value } of carTypes) {
        allCarTypes[value] = allCarTypes[value]
          ? [...allCarTypes[value], i]
          : [i]

        if (allCarTypes[value].length > 1) {
          allCarTypes[value].forEach((pos) => {
            errors[pos] = CAR_TYPE_DUPLICATE_MESSAGE
          })
        }
      }
    }

    return errors
  }

  const checkExceptions = (values: IFormValues['details']) => {
    const errors: ExceptionErrors = []
    let duplicatesErrors: string[] = []

    const checkValue = (
      key: keyof typeof RENTAL_INCLUDES_FORM_VALUES,
      value?: string
    ) => {
      if (value === '0') {
        return RENTAL_INCLUDES_FORM_VALUES[key].VALIDATION_MESSAGE
      } else if (!value) {
        return RENTAL_INCLUDES_FORM_VALUES[key].REQUIRED_MESSAGE
      }
      return ''
    }

    if (values) {
      duplicatesErrors = checkDuplicates(values)
    }

    values?.forEach(({ distance, durationDays, carTypes }, i) =>
      errors.push({
        distance: checkValue('DISTANCE', distance),
        duration: checkValue('PERIOD', durationDays),
        carTypes: carTypes?.length
          ? duplicatesErrors[i]
          : RENTAL_INCLUDES_FORM_VALUES.CAR_TYPES.REQUIRED_MESSAGE,
      })
    )

    setExceptionsErrors(errors)

    return errors.some((error) => Object.values(error).some((error) => error))
  }

  return { exceptionsErrors, checkExceptions, setExceptionsErrors }
}
