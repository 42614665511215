import { DailyRange } from 'api/fleet/types'
import { RangeType } from './types'

export const DAILY_TYPE = 'daily'
export const SEASON_TYPE = 'season'
export const INFINITY = '+'

export const initialDailyRange: DailyRange = {
  rangeStart: '',
  rangeEnd: INFINITY,
  rangeType: 'Daily',
  uniqueId: '',
}

export const initialSeasonRange = {
  start: '',
  end: '',
  uniqueId: '',
}

export const initialValues: {
  [key: string]: RangeType
} = {
  season: initialSeasonRange,
  daily: initialDailyRange,
}
