import { forwardRef } from 'react'
import { LocationsApiTypes } from 'api'
import { WeekDay } from './components/WeekDay/WeekDay'
import {
  LOCATION_ADDING_FORM_VALUES,
  LOCATION_TABS,
  NEXT_BUTTON_TITLE,
} from 'texts/locationDetails'
import { OriginButton, Badge, Typography } from '@frontend/design_system'
import arrowRight from 'assets/img/arrowRight.webp'
import { WorkingHoursFormProps } from './types'
import { FormAlertLabel } from 'ui/atoms/FormAlertLabel'
import { scrollTop } from 'utils/scrollTop'
import { checkWorkingHourErrorsExist } from '../../utils'
import styles from './styles.module.scss'

export const WorkingHoursForm = forwardRef<
  HTMLDivElement,
  WorkingHoursFormProps
>(
  (
    {
      onWorkingHoursChange,
      workingHours,
      error,
      changeActiveKey,
      validateWorkingHours,
      errors,
      resetError,
      formKey,
      onWorkingHoursUpdate,
    },
    ref
  ) => {
    const handleChange = (
      index: number,
      hours: LocationsApiTypes.WorkingHour
    ) => {
      const copiedWorkingHours = [...workingHours]
      copiedWorkingHours.splice(index, 1, hours)
      onWorkingHoursChange(copiedWorkingHours)
    }

    const goNextTab = () => {
      const preparedWorkingHours = validateWorkingHours()
      onWorkingHoursUpdate()
      if (preparedWorkingHours.length) {
        scrollTop()
        changeActiveKey(LOCATION_TABS.RESERVATION)
      }
    }

    const onBlur = (hours: LocationsApiTypes.WorkingHour) => {
      const copiedWorkingHours = [...workingHours]
      const a = copiedWorkingHours.map((workingHour, i) => {
        const SATURDAY_INDEX = 5
        if (i < SATURDAY_INDEX) {
          return {
            ...workingHour,
            dayStart: workingHour.dayStart || hours.dayStart,
            dayEnd: workingHour.dayEnd || hours.dayEnd,
            dayStart2: workingHour.dayStart2 || hours.dayStart2,
            dayEnd2: workingHour.dayEnd2 || hours.dayEnd2,
          }
        } else {
          return workingHour
        }
      })
      onWorkingHoursChange(a)
    }

    return (
      <div ref={ref} key={formKey}>
        <Typography
          name="subtitleWBold"
          html={LOCATION_ADDING_FORM_VALUES.WORKING_HOURS.TITLE}
          markColor="red600Master"
        />
        <Badge
          message={LOCATION_ADDING_FORM_VALUES.WORKING_HOURS.INFO}
          type="info"
          iconSize="medium"
          className={styles.badge}
        />
        {workingHours.map((day, i) => {
          const isMonday = i === 0
          return (
            <WeekDay
              workingHours={day}
              key={i}
              errors={errors[day.dayOfWeek]}
              day={day.dayOfWeek}
              onBlur={isMonday ? () => onBlur(day) : undefined}
              handleChange={(hours) => handleChange(i, hours)}
              resetError={resetError}
            />
          )
        })}
        {error && !checkWorkingHourErrorsExist(errors) && (
          <FormAlertLabel label={error} position="static" type="error" />
        )}
        <div className={styles['form-item-right']}>
          <div className={styles['form-next-button']}>
            <OriginButton
              variant="link"
              size="large"
              onClick={goNextTab}
              label={NEXT_BUTTON_TITLE}
              typographyName="Button1"
              iconRight={<img src={arrowRight} width={16} height={16} />}
            />
          </div>
        </div>
      </div>
    )
  }
)
