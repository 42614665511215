const prepareOptions = (obj: { [key: number]: string }) =>
  Object.entries(obj)
    .filter(([, value]) => typeof value !== 'string')
    .map(([label, value]) => ({ label, value: +value }))

export enum VEHICLE_PERIOD_UNIT_NAMES {
  Unknown = 0,
  Month = 1,
  Week = 2,
  Day = 3,
  'Rental Period' = 5,
}

export const VEHICLE_PERIOD_UNIT_NAMES_OPTIONS = prepareOptions(
  VEHICLE_PERIOD_UNIT_NAMES
)

export enum DISTANCE_UNIT_NAMES {
  km = 0,
  mi = 1,
}

export const DISTANCE_UNIT_NAMES_OPTIONS = prepareOptions(DISTANCE_UNIT_NAMES)

export enum CAR_TYPES {
  'Mini' = 1,
  'Mini Elite' = 2,
  'Economy' = 3,
  'Economy Elite' = 4,
  'Compact' = 5,
  'Compact Elite' = 6,
  'Intermediate' = 7,
  'Intermediate Elite' = 8,
  'Standard' = 9,
  'Standard Elite' = 10,
  'Fullsize' = 11,
  'Fullsize Elite' = 12,
  'Premium' = 13,
  'Premium Elite' = 14,
  'Luxury' = 15,
  'Luxury Elite' = 16,
  'Oversize' = 17,
  'Special' = 18,
  'Van' = 19,
  'Electric' = 20,
  'Midsize' = 21,
  'SUV' = 22,
  'Estate' = 23,
  'Convertible' = 24,
  'Small' = 25,
}

export const CAR_TYPES_OPTIONS = prepareOptions(CAR_TYPES)
export const CAR_TYPES_VALUES = Object.fromEntries(
  Object.entries(CAR_TYPES).map(([key, value]) => [value, key])
)

export enum MILEAGE_TYPES {
  Unlimited = 0,
  Limited = 1,
}

export enum TERM_CONDITIONS_OPTIONS {
  LocalTaxes = 1,
  Insurance = 2,
  AirportSurcharge = 3,
  RoadAssistance24h = 4,
  FinesAndTollFeesAndParkingCharges = 5,
  AdditionalEquipment = 6,
}

export enum TERM_CONDITIONS_TYPES {
  DriverCreditCard = 0,
  RentalIncludes = 2,
  DriverRequirements = 7,
  RentalExcludes = 8,
  FuelPolicy = 9,
  InsuranceIncluded = 14,
  ExcessDeposit = 10,
  CrossBorderPolicy = 17,
}

export enum FUEL_POLICY_TYPES {
  FullToFull = 1,
  FullToEmpty = 2,
}

export enum INSURANCE {
  Include = 1,
  Exclude = 2,
}

export enum CROSS_BORDER_POLICY_TYPES {
  AllowedTo = 1,
  NotAllowed = 2,
}

export enum CAR_TYPE_FOR_DEPOSIT {
  Mini = 0,
  Economy = 1,
  Compact = 2,
  Intermediate = 3,
  Standard = 4,
  Fullsize = 5,
  Van = 6,
  SUV = 7,
  Premium = 8,
}

export const CAR_TYPE_FOR_DEPOSIT_TABLE_ITEMS =
  prepareOptions(CAR_TYPE_FOR_DEPOSIT)
