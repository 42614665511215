import { useCallback, useEffect, useRef, useState } from 'react'
import { TableHead } from './TableHead'
import { TableRow } from './TableRow'
import { TableProps } from './types'
import noDataSrc from 'assets/img/noDataImage.png'
import { joinClasses } from 'utils/joinClasses'
import { Typography } from 'ui/atoms/Typography'
import styles from './styles.module.scss'
import { NO_DATA } from 'texts/uiTexts'

const DEFAULT_ITEM_OFFSET = 24

export const Table = ({
  headItems,
  bodyItems,
  className = '',
  isStickyHeader,
  initItemsWidth,
  footerItems,
  rowItemWrap,
  dataTestId,
}: TableProps) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const tableElement = useRef<HTMLTableElement | null>(null)
  const tableContainerElement = useRef<HTMLDivElement | null>(null)
  const [hasVerticalScroll, setHasVerticalScroll] = useState(false)
  const [containerWidth, setContainerWidth] = useState(0)

  const headersRef = useRef<HTMLTableCellElement[] | null[]>([])

  const mouseDown = (index: number) => {
    setActiveIndex(index)
  }

  const mouseMove = useCallback(
    (e: MouseEvent) => {
      if (tableElement.current && activeIndex !== null) {
        const active = headersRef.current[activeIndex]
        const lastChild = headersRef.current[headItems.length - 1]

        const offsetLeft = tableElement.current.getBoundingClientRect().left
        const tableWidth = tableElement?.current?.getBoundingClientRect().width
        const activeInitWidth = initItemsWidth?.[activeIndex]
        if (active) {
          if (activeInitWidth) {
            const isActiveMoreThanCalculated =
              Number(activeInitWidth) >
              e.clientX - offsetLeft - active.offsetLeft + DEFAULT_ITEM_OFFSET
            active.style.minWidth = `${
              isActiveMoreThanCalculated
                ? activeInitWidth
                : e.clientX -
                  offsetLeft -
                  active.offsetLeft +
                  DEFAULT_ITEM_OFFSET
            }px`
          } else {
            active.style.minWidth = `${
              e.clientX - offsetLeft - active.offsetLeft + DEFAULT_ITEM_OFFSET
            }px`
          }
        }

        if (lastChild && containerWidth && tableWidth && active) {
          let lastChildWidth
          if (initItemsWidth?.[initItemsWidth.length - 1]) {
            lastChildWidth = Number(initItemsWidth?.[initItemsWidth.length - 1])
          } else {
            lastChildWidth = lastChild.getBoundingClientRect().width
          }
          if (containerWidth > tableWidth) {
            const difference = containerWidth - tableWidth
            lastChild.style.minWidth = `${lastChildWidth + difference}px`
          } else {
            lastChild.style.minWidth = `${lastChildWidth}px`
          }
        }
      }
    },
    [activeIndex]
  )

  const mouseEnter = useCallback(() => {
    if (
      tableContainerElement.current &&
      tableContainerElement.current.scrollHeight >
        tableContainerElement.current.clientHeight
    ) {
      setHasVerticalScroll(true)
    }
  }, [])

  const mouseLeave = useCallback(() => {
    setHasVerticalScroll(false)
  }, [])

  const removeListeners = useCallback(() => {
    window.removeEventListener('mousemove', mouseMove)
    window.removeEventListener('mouseup', removeListeners)
    if (tableContainerElement.current) {
      tableContainerElement.current.removeEventListener(
        'mouseenter',
        mouseEnter
      )
      tableContainerElement.current.removeEventListener(
        'mouseleave',
        mouseLeave
      )
    }
  }, [mouseMove, mouseEnter])

  useEffect(() => {
    if (tableContainerElement.current) {
      tableContainerElement.current.addEventListener('mouseenter', mouseEnter)
      tableContainerElement.current.addEventListener('mouseleave', mouseLeave)
    }

    return () => {
      removeListeners()
    }
  }, [tableContainerElement.current])

  const mouseUp = useCallback(() => {
    setActiveIndex(null)
    removeListeners()
  }, [setActiveIndex, removeListeners])

  useEffect(() => {
    if (activeIndex !== null) {
      window.addEventListener('mousemove', mouseMove)
      window.addEventListener('mouseup', mouseUp)
    }

    return () => {
      removeListeners()
    }
  }, [activeIndex, mouseMove, mouseUp, removeListeners])

  useEffect(() => {
    const width = tableContainerElement?.current?.getBoundingClientRect().width
    if (width) {
      setContainerWidth(width)
    }
  }, [tableContainerElement])

  return (
    <div
      className={joinClasses(styles['table-container'], [
        styles['table-container-sticky'],
        isStickyHeader,
      ])}
      ref={tableContainerElement}
    >
      <table
        ref={tableElement}
        className={joinClasses(styles.table, className)}
        data-testid={dataTestId}
      >
        <thead className={styles['table-thead']}>
          <tr className={styles['table-tr']}>
            {headItems.map((header, index: number) => (
              <TableHead
                mouseDown={() => mouseDown(index)}
                ref={(el) => {
                  headersRef.current[index] = el
                  return el
                }}
                itemsCount={headItems.length}
                isLastChild={headItems.length - 1 === index}
                containerWidth={containerWidth}
                index={index}
                isEmptyTable={!bodyItems?.length}
                initItemsWidth={initItemsWidth}
                key={index}
                hasVerticalScroll={hasVerticalScroll}
                item={header}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {bodyItems?.map((items, index) => (
            <TableRow
              key={items.key || index}
              indexRow={index}
              onClick={items?.onClick}
              rowItems={items.items}
              isActive={items.isActive}
              removeRowClick={items?.removeRowClick}
              rowItemWrap={rowItemWrap}
              dataTestId={dataTestId}
            />
          ))}
        </tbody>
        {!!footerItems?.length && !!bodyItems?.length && (
          <tfoot className={styles['table-foot']}>
            <tr>
              {footerItems.map((item, index: number) => (
                <td key={index}>{item}</td>
              ))}
            </tr>
          </tfoot>
        )}
      </table>
      {!bodyItems?.length && (
        <div className={styles['no-data']} data-testid="no-data-block">
          <img src={noDataSrc} className={styles['no-data-image']} />
          <Typography name="subtitleWBold">{NO_DATA}</Typography>
        </div>
      )}
    </div>
  )
}
