import { breadcrumbs } from 'constants/breadcrumbs'
import { UserRoles } from 'constants/roles'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { userRoleSelector } from 'redux/login/selectors'
import { MENU_NAMES } from 'texts/menuNames'
import {
  ArrowRightIcon,
  InfoIcon,
  MaximizeIcon,
  ModalCloseIcon,
} from 'ui/icons'
import { Pagination, Tooltip, Button, Select } from '@frontend/design_system'
import { OldEditableCell, Table } from 'ui/molecules/Table'
import { Confirmation } from 'ui/molecules/Confirmation'
import { Container } from 'ui/molecules/Container'
import { CANCEL, PAGINATION_DROPDOWN_LABEL, SAVE } from 'texts/uiTexts'
import {
  DEFAULT_INITIAL_PAGE,
  DEFAULT_INITIAL_PAGE_SIZE,
} from 'constants/pagination'
import { VEHICLES, VEHICLES_DELETE_CONFIRMATION } from 'texts/vehicles'
import { Modal } from 'ui/molecules/Modal'
import { URLS } from 'constants/urls'
import { fleetApi } from 'api'
import {
  PriceListCar,
  PriceListRate,
  PriceListRateEdit,
  PriceListRates,
  CarClassItem,
} from 'api/fleet/types'
import { ItemType } from 'ui/atoms/Select/types'
import { useDispatch, useSelector } from 'react-redux'
import { PRICES_BACK_BUTTON, RATES } from 'texts/rateDetails'
import { useApiRequest } from 'hooks/useApiRequest'
import { useReadOnlyMode } from '../../hooks/useReadOnlyMode'
import { PRICE_LIST_DETAILS_BREADCRUMB } from 'texts/priceListDetails'
import { CarClassAddModal } from './components/CarClassAddModal'
import { setNotificationMessage } from 'redux/notifications/slice'
import { SUCCESS_UPDATE_MESSAGE } from 'texts/common'
import { ImportRate } from './components/ImportRate'
import { ExportRate } from './components/ExportRate'
import { TextField } from 'ui/atoms/TextField'
import { Typography } from 'ui/atoms/Typography'
import { formatSeasonRangeWithOneYear } from 'utils/seasonRanges'
import { getRatesHeadItems } from './utilts'
import { useCompanyId } from 'hooks/useCompanyId'
import { MAX_TEXT_FIELD_LENGTH } from './constants'
import styles from './styles.module.scss'

export const Rates = () => {
  const params = useParams()
  const [page, setPage] = useState(DEFAULT_INITIAL_PAGE)
  const [availableCarClasses, setAvailableCarClasses] = useState<
    CarClassItem[]
  >([])
  const [pageSize, setPageSize] = useState(DEFAULT_INITIAL_PAGE_SIZE)
  const [rates, setRates] = useState<PriceListRates | null>(null)
  const [isUpdated, setUpdated] = useState(false)
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentSeasonRange, setCurrentSeasonRange] = useState({
    value: '',
    label: '',
  })
  const [tableContent, setTableContent] = useState<PriceListCar[]>([])
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null)
  const hasSelectedRow = selectedRowIndex !== null

  const dispatch = useDispatch()

  const priceListId = params.priceListId as string
  const role = useSelector(userRoleSelector)

  const isOperator = role === UserRoles.Operator

  const popupWidth = 270
  const popupWidthRef = useRef(popupWidth)

  const companyId = useCompanyId()

  const isReadOnlyMode = useReadOnlyMode(rates?.year)

  const navigate = useNavigate()
  const priceListRatesResponse = useApiRequest((priceListId) =>
    fleetApi.getPriceListRates(priceListId, Number(pageSize), page - 1)
  )

  const handleUpdate = () => {
    setUpdated(true)
  }

  const updateRatesResponse = useApiRequest(
    (priceListId, filteredRates) =>
      fleetApi.setPriceListRates(priceListId, filteredRates),
    undefined,
    undefined,
    undefined,
    false
  )

  const breadcrumbList = useMemo(() => {
    const supplierBreadcrumbList = [
      {
        ...breadcrumbs.priceLists,
        link: `${breadcrumbs.priceLists.link}/${companyId}`,
      },
      {
        link: `${URLS.FLEET}${URLS.PRICE_LIST}/${companyId}/${priceListId}/edit`,
        label: PRICE_LIST_DETAILS_BREADCRUMB,
      },
    ]

    if (isOperator) {
      supplierBreadcrumbList.unshift({
        ...breadcrumbs.profileList,
        link: `${breadcrumbs.profileList.link}`,
      })
    }

    return supplierBreadcrumbList
  }, [priceListId, companyId])

  const filterContent = (content: PriceListCar[]) => {
    const dailyRanges = rates?.dailyRanges.map((el) => el.dailyRangeId)

    const filteredContent = content.map((el) => ({
      ...el,
      rates: el.rates.filter(
        (el) => String(el.seasonRangeId) === currentSeasonRange.value
      ),
    }))

    filteredContent.forEach((item) => {
      const existingDailyRangeIds = item.rates.map((rate) => rate.dailyRangeId)

      dailyRanges?.forEach((id) => {
        if (!existingDailyRangeIds.includes(id)) {
          item.rates.push({
            price: 0,
            dailyRangeId: id,
            seasonRangeId: Number(currentSeasonRange.value),
          })
        }
      })

      item.rates = item.rates
        .filter((rate) => dailyRanges?.includes(Number(rate.dailyRangeId)))
        .sort((a, b) => a.dailyRangeId - b.dailyRangeId)
    })

    return filteredContent
  }

  const fetchRates = async () => {
    const priceListRates = await priceListRatesResponse.apiRequest(priceListId)
    if (priceListRates) {
      setAvailableCarClasses(priceListRates.data.availableCarClasses)
      setRates(priceListRates.data)
      const currentSeasonRange =
        priceListRates.data.seasonRanges[0].description +
        priceListRates.data.year
      setCurrentSeasonRange({
        label: currentSeasonRange,
        value: String(priceListRates.data.seasonRanges[0].seasonRangeId),
      })
    }
  }

  useEffect(() => {
    if (priceListId) fetchRates()
  }, [page, pageSize])

  useEffect(() => {
    if (rates) {
      setTableContent(filterContent(rates.cars.pageItems))
    }
  }, [currentSeasonRange, rates])

  const handleChangeRange = (item: ItemType) => {
    setCurrentSeasonRange({
      value: item.value,
      label: item.label,
    })
    setTableContent(filterContent(tableContent))
  }

  const changePageSize = (value: string) => {
    setPageSize(value)
    setPage(DEFAULT_INITIAL_PAGE)
  }

  const handleChange = (pageNumber: number) => {
    setPage(pageNumber)
  }

  const mergeRates = useCallback(
    (tableContent: PriceListCar[], newRestRates?: PriceListCar[]) => {
      const ratesList = newRestRates || rates?.cars.pageItems
      if (ratesList) {
        const ratesFromUnselectedRanges = ratesList.map((el) =>
          el.rates.filter(
            (el) => el.seasonRangeId !== Number(currentSeasonRange.value)
          )
        )
        const allRates = [...tableContent].map((priceListCar, i) => ({
          ...priceListCar,
          rates: [
            ...priceListCar.rates,
            ...(ratesFromUnselectedRanges[i] || []),
          ],
        }))
        return allRates
      }
    },
    [rates, tableContent, currentSeasonRange]
  )

  const handleChangeValue = useCallback(
    (col: number, rowIndex: number, value: number) => {
      const allRates = mergeRates(tableContent)
      const newTableContent = [...tableContent]
      newTableContent[rowIndex].rates[col].price = value

      setTableContent(newTableContent)
      if (allRates) {
        setRates((prevState) =>
          prevState !== null
            ? {
                ...prevState,
                cars: {
                  ...prevState?.cars,
                  pageItems: [...allRates],
                },
              }
            : null
        )
      }
    },
    [tableContent, mergeRates]
  )

  const formattedHeadItems = useMemo(() => {
    const items = getRatesHeadItems(rates?.priceListType).map((header) => ({
      value: header,
    }))
    rates?.dailyRanges?.forEach((el) => items.push({ value: el.description }))
    return items
  }, [rates])

  const handleModalClose = () => {
    setConfirmationModalOpen(false)
    setSelectedRowIndex(null)
  }

  const getEditableRates = (rates: PriceListRate[], row: number) =>
    rates.map((el, col) => (
      <OldEditableCell
        key={Date.now() * el.price}
        handleUpdate={handleUpdate}
        onChange={(value) => handleChangeValue(col, row, Number(value))}
        initValue={el.price}
        readOnly={isReadOnlyMode}
      />
    ))

  const handleSave = async (
    tableContent: PriceListCar[],
    newRestRates?: PriceListCar[]
  ) => {
    const allRates = mergeRates(tableContent, newRestRates)
    if (allRates) {
      const formattedRates: PriceListRateEdit[] = []
      allRates.forEach((car) =>
        car.rates.forEach((rate) =>
          formattedRates.push({
            ...rate,
            carClass: car.carClass,
          })
        )
      )

      const response = await updateRatesResponse.apiRequest(
        priceListId,
        formattedRates
      )
      if (response) {
        setSelectedRowIndex(null)
        setConfirmationModalOpen(false)
        setUpdated(false)
        dispatch(
          setNotificationMessage({
            notificationMessage: SUCCESS_UPDATE_MESSAGE,
          })
        )
        fetchRates()
      }
    }
  }

  const handleDeleteRange = (row: number) => {
    setConfirmationModalOpen(true)
    setSelectedRowIndex(row)
  }

  const handleRemoveRow = async () => {
    if (hasSelectedRow) {
      const newContent = [...tableContent]
      const newRestRates = rates?.cars.pageItems
        ? [...rates?.cars.pageItems]
        : []
      newRestRates.splice(selectedRowIndex, 1)
      newContent.splice(selectedRowIndex, 1)
      await handleSave(newContent, newRestRates)
    }
  }

  const formattedBodyItems = useMemo(
    () =>
      tableContent.map((el, row) => ({
        items: [el.carClass, el.carName, ...getEditableRates(el.rates, row)],
        removeRowClick: isReadOnlyMode
          ? undefined
          : () => handleDeleteRange(row),
      })),
    [tableContent, currentSeasonRange.value, isReadOnlyMode]
  )
  const handleCancel = () => {
    if (isModalOpen) {
      setIsModalOpen(false)
    } else {
      navigate(`${URLS.FLEET}${URLS.PRICE_LIST}/${companyId}`)
    }
  }

  const saveTableContent = async () => handleSave(tableContent)

  const handleBack = () => {
    navigate(`${URLS.FLEET}${URLS.PRICE_LIST}/${companyId}/${priceListId}/edit`)
  }

  const renderTable = () => (
    <>
      <div className={styles.list}>
        <div className={styles.header}>
          <div className={styles.buttons}>
            <ImportRate
              priceListId={priceListId}
              updateRates={fetchRates}
              disabled={isReadOnlyMode}
            />
            <ExportRate
              priceListId={priceListId}
              disabled={!rates?.cars.pageItems.length || isReadOnlyMode}
              isUpdated={isUpdated}
              saveTableContent={saveTableContent}
            />
            <div className={styles['car-class-wrapper']}>
              <CarClassAddModal
                tableContent={tableContent}
                carClasses={availableCarClasses}
                handleChangeContent={handleSave}
                rates={rates}
                disabled={isReadOnlyMode}
                dailyRangesId={
                  rates?.dailyRanges.map((el) => el.dailyRangeId) || []
                }
              />
            </div>
            <div className={styles['filter-icon']}>
              {!isModalOpen ? (
                <MaximizeIcon
                  color="blue700"
                  size="large"
                  onClick={() => setIsModalOpen(true)}
                />
              ) : (
                <ModalCloseIcon
                  size="medium"
                  onClick={() => setIsModalOpen(false)}
                />
              )}
            </div>
          </div>
        </div>
        {formattedBodyItems && (
          <div className={styles.table}>
            <Table
              headItems={formattedHeadItems}
              bodyItems={formattedBodyItems}
            />
          </div>
        )}
      </div>
      <footer className={styles.pagination}>
        <Pagination
          current={page}
          total={rates?.cars.totalItems || 0}
          pageSize={pageSize}
          changePageSize={changePageSize}
          changePage={handleChange}
          pageSizeLabel={PAGINATION_DROPDOWN_LABEL}
        />
      </footer>
      <div className={styles['action-buttons']}>
        <div className={styles['back-button']}>
          <Button
            label={PRICES_BACK_BUTTON}
            variant="link"
            typographyName="body1WMedium"
            iconLeft={<ArrowRightIcon rotate={180} size="medium" />}
            onClick={handleBack}
          />
        </div>
        <div className={styles['right-side']}>
          <div className={styles['action-button']}>
            <Button
              label={CANCEL}
              variant="secondary-border"
              onClick={handleCancel}
            />
          </div>
          <div className={styles['action-button']}>
            <Button
              label={SAVE}
              disabled={isReadOnlyMode || !isUpdated}
              onClick={saveTableContent}
              loading={updateRatesResponse.loading}
            />
          </div>
        </div>
      </div>
    </>
  )

  const renderTextField = (value = '', label: string) => (
    <div className={styles.wrapper}>
      <TextField
        label={label}
        value={
          <div className={styles['location-info']}>
            <Typography Tag="div" name="Button2" className={styles.location}>
              {value}
            </Typography>
            {value.length > MAX_TEXT_FIELD_LENGTH && (
              <Tooltip
                size="primary"
                widthRef={popupWidthRef}
                arrowPosition="right"
                popupContent={<Typography name="Button2">{value}</Typography>}
                placement="bottom"
              >
                <InfoIcon color="blue700" size="medium" />
              </Tooltip>
            )}
          </div>
        }
      />
    </div>
  )

  return (
    <>
      {isModalOpen && (
        <Modal
          position="center-horizontal"
          onClose={() => setIsModalOpen(false)}
          offsetVariant="none"
          invisibleTitle
          invisibleHeader
          invisibleClose
          headerSize="large"
        >
          <div className={styles['rates-modal']}>{renderTable()}</div>
        </Modal>
      )}
      <Container
        breadcrumbList={breadcrumbList}
        currentPageLabel={MENU_NAMES.RATES}
        title={MENU_NAMES.RATES}
        withNavigation={isOperator}
        loading={priceListRatesResponse.loading}
      >
        <>
          <div>
            <div className={styles['range-wrapper']}>
              {!rates?.seasonRanges || rates?.seasonRanges.length > 1 ? (
                <Select
                  items={
                    rates?.seasonRanges?.map((el) => ({
                      label: formatSeasonRangeWithOneYear(
                        el.description,
                        rates.year
                      ),
                      value: String(el.seasonRangeId),
                    })) || []
                  }
                  label={RATES.SEASON_RANGES}
                  onChange={(_value: string, item: ItemType) =>
                    handleChangeRange(item)
                  }
                  selectedValue={currentSeasonRange.value}
                  size="large"
                />
              ) : (
                <div className={styles['single-range']}>
                  <Typography
                    Tag="div"
                    name="Button2"
                    className={styles['single-range-block-title']}
                  >
                    {RATES.SEASON_RANGES}
                  </Typography>
                  <Typography
                    Tag="div"
                    name="Button2"
                    className={styles['single-range-block-value']}
                  >
                    {formatSeasonRangeWithOneYear(
                      rates?.seasonRanges[0].description,
                      rates.year
                    )}
                  </Typography>
                </div>
              )}
            </div>
            <div className={styles['rate-info']}>
              {renderTextField(rates?.country, RATES.COUNTRY)}
              {renderTextField(rates?.location, RATES.LOCATION)}
              {renderTextField(rates?.countriesOfResidence, RATES.COR)}
            </div>
          </div>
          {renderTable()}
        </>
      </Container>
      {isConfirmationModalOpen && hasSelectedRow && (
        <Confirmation
          confirmModalProps={{
            question: `${VEHICLES_DELETE_CONFIRMATION.CONFIRMATION_MODAL.QUESTION} ${tableContent[selectedRowIndex].carName}?`,
            onConfirm: handleRemoveRow,
            onClose: handleModalClose,
            title: `${VEHICLES.DELETE} ${tableContent[selectedRowIndex].carName} ${VEHICLES.MODEL}`,
          }}
        />
      )}
    </>
  )
}
