import {
  ADDED_SUCCESS,
  EXCEPTIONS,
  EXCEPTIONS_BUTTON,
  INSURANCE_TOOLTIP,
  MILEAGE,
  RENTAL_INCLUDES,
  RENTAL_INCLUDES_DEFAULT_MESSAGE,
  RENTAL_INCLUDES_FORM_VALUES,
  RENTAL_INCLUDES_LIMIT,
  UPDATED_SUCCESS,
} from 'texts/termsAndConditions'
import { Block } from '../Block'
import {
  Form,
  FormItem,
  Checkbox,
  Typography,
  Tooltip,
  Button,
} from '@frontend/design_system'
import { FormChildren, FormItemChildren } from 'types/form'
import { ExceptionError, IFormValues } from './types'
import { ActionButtons } from '../ActionButtons'
import styles from './styles.module.scss'
import { useEffect, useMemo, useState } from 'react'
import { InfoIcon } from 'ui/icons'
import { CorBtn } from '../CorBtn'
import { COR_ITEMS } from 'constants/countryOfResidence'
import { Row } from '../Row'
import {
  CAR_TYPES_VALUES,
  MILEAGE_TYPES,
  TERM_CONDITIONS_OPTIONS,
  TERM_CONDITIONS_TYPES,
} from 'constants/termsAndConditions'
import { useApiRequest } from 'hooks/useApiRequest'
import { termsApi } from 'api'
import { INewTermConditionBody } from 'api/terms/types'
import { prepareResponseItems, prepareCorForSelect } from 'utils/form'
import { useDispatch } from 'react-redux'
import { setNotificationMessage } from 'redux/notifications/slice'
import { IContentProps } from 'modules/TermsAndConditions/types'
import { useCompanyId } from 'hooks/useCompanyId'
import { Exception } from './components/Exception/Exception'
import { useCheckExceptionErrors } from './hooks/useCheckExceptionErrors'

export const RentalIncludes = ({
  reload,
  data,
  isHistoryExist,
}: IContentProps) => {
  const dispatch = useDispatch()
  const companyId = useCompanyId()
  const [mileageType, setMileageType] = useState(MILEAGE_TYPES.Unlimited)
  const newTermConditionRequest = useApiRequest(
    (body) => termsApi.newTermCondition(body),
    undefined,
    true,
    undefined,
    false
  )
  const updateTermConditionRequest = useApiRequest(
    (body) => termsApi.updateTermCondition(body),
    undefined,
    true,
    undefined,
    false
  )

  const { checkExceptions, exceptionsErrors, setExceptionsErrors } =
    useCheckExceptionErrors()

  const isUmlimitedMileage = mileageType === MILEAGE_TYPES.Unlimited

  const handleOpenExceptions = () => {
    setMileageType(MILEAGE_TYPES.Limited)
  }

  useEffect(() => {
    if (data?.mileageType) setMileageType(data?.mileageType)
  }, [data?.mileageType])

  const initValues = useMemo(() => {
    const initData: IFormValues = {
      cor: !isHistoryExist ? COR_ITEMS : [],
      localTaxes: true,
      insurance: true,
      airportSurcharge: false,
      roadAssistance: false,
      mileageType: RENTAL_INCLUDES_FORM_VALUES.UNLIMITED.ID as MILEAGE_TYPES,
      details: [
        {
          id: Date.now(),
          carTypes: [],
          distance: '',
          distanceUnit: 0,
          durationDays: '',
        },
      ],
      period: undefined,
    }

    if (data) {
      initData.cor = prepareCorForSelect(data.countriesOfResidence)
      initData.localTaxes =
        data.options?.includes(TERM_CONDITIONS_OPTIONS.LocalTaxes) || false
      initData.insurance =
        data.options?.includes(TERM_CONDITIONS_OPTIONS.Insurance) || false
      initData.airportSurcharge =
        data.options?.includes(TERM_CONDITIONS_OPTIONS.AirportSurcharge) ||
        false
      initData.roadAssistance =
        data.options?.includes(TERM_CONDITIONS_OPTIONS.RoadAssistance24h) ||
        false
      initData.mileageType = data.mileageType || mileageType
      initData.period = data.period

      if (data.details?.length) {
        initData.details = data.details?.map((detail, id) => ({
          ...detail,
          id,
          carTypes:
            detail.carTypes?.map((carTypeId) => ({
              value: String(carTypeId),
              label: CAR_TYPES_VALUES[carTypeId],
            })) || [],
        }))
      }
    }

    return initData
  }, [data])

  const handleChangeError = (index: number, key: keyof ExceptionError) => {
    const newExceptionErrors = [...exceptionsErrors]
    if (newExceptionErrors[index] && newExceptionErrors[index][key]) {
      newExceptionErrors[index][key] = ''
      setExceptionsErrors(newExceptionErrors)
    }
  }

  const onSubmit = async (values: IFormValues, validate: boolean) => {
    const isErrorsExist = !isUmlimitedMileage && checkExceptions(values.details)

    const isFormValid = !isUmlimitedMileage
      ? !isErrorsExist && validate
      : validate

    if (isFormValid) {
      const options = []
      if (values.localTaxes) {
        options.push(TERM_CONDITIONS_OPTIONS.LocalTaxes)
      }
      if (values.insurance) {
        options.push(TERM_CONDITIONS_OPTIONS.Insurance)
      }
      if (values.airportSurcharge) {
        options.push(TERM_CONDITIONS_OPTIONS.AirportSurcharge)
      }
      if (values.roadAssistance) {
        options.push(TERM_CONDITIONS_OPTIONS.RoadAssistance24h)
      }

      const body: INewTermConditionBody = {
        termConditionTypeId: TERM_CONDITIONS_TYPES.RentalIncludes,
        companyId,
        countriesOfResidence: prepareResponseItems(values.cor),
        mileageType,
        options,
      }

      if (!isUmlimitedMileage) {
        body.details = values.details?.map((value) => ({
          ...value,
          carTypes: value.carTypes?.map((carType) => Number(carType.value)),
        }))
      }

      if (data) {
        await updateTermConditionRequest.apiRequest({
          ...body,
          termConditionSettingId: data.id,
        })
        dispatch(
          setNotificationMessage({
            notificationMessage: UPDATED_SUCCESS,
          })
        )
      } else {
        await newTermConditionRequest.apiRequest(body)
        dispatch(
          setNotificationMessage({
            notificationMessage: ADDED_SUCCESS,
          })
        )
      }

      reload()
    }
  }

  return (
    <Block title={RENTAL_INCLUDES}>
      <Form initValues={initValues} onSubmit={onSubmit} key={data?.id}>
        {({ isDirty }: FormChildren) => (
          <>
            <CorBtn />
            <Row isOffset>
              <div>
                <FormItem id={RENTAL_INCLUDES_FORM_VALUES.LOCAL_TAXES.ID}>
                  {({ value, onChange }: FormItemChildren) => (
                    <Checkbox
                      checked={value}
                      onChange={onChange}
                      variant="bcrm"
                      borderVariant="grey"
                      size="newFontsSecondary"
                      label={RENTAL_INCLUDES_FORM_VALUES.LOCAL_TAXES.LABEL}
                    />
                  )}
                </FormItem>
              </div>
              <div>
                <FormItem
                  id={RENTAL_INCLUDES_FORM_VALUES.INSURANCE.ID}
                  className={styles.insurance}
                >
                  {({ value, onChange }: FormItemChildren) => (
                    <>
                      <Checkbox
                        checked={value}
                        onChange={onChange}
                        variant="bcrm"
                        borderVariant="grey"
                        size="newFontsSecondary"
                        label={RENTAL_INCLUDES_FORM_VALUES.INSURANCE.LABEL}
                      />
                      <Tooltip
                        size="secondary"
                        popupContent={
                          <Typography
                            name="caption1WMedium"
                            className={styles['insurance-tooltip']}
                            Tag="div"
                          >
                            {INSURANCE_TOOLTIP}
                          </Typography>
                        }
                        placement="bottom"
                      >
                        <InfoIcon color="blue700" size="small" />
                      </Tooltip>
                    </>
                  )}
                </FormItem>
              </div>
              <div>
                <FormItem id={RENTAL_INCLUDES_FORM_VALUES.AIRPORT_SURCHARGE.ID}>
                  {({ value, onChange }: FormItemChildren) => (
                    <Checkbox
                      checked={value}
                      onChange={onChange}
                      variant="bcrm"
                      borderVariant="grey"
                      size="newFontsSecondary"
                      label={
                        RENTAL_INCLUDES_FORM_VALUES.AIRPORT_SURCHARGE.LABEL
                      }
                    />
                  )}
                </FormItem>
              </div>
            </Row>
            <div className={styles['road-assistance']}>
              <Row>
                <div>
                  <FormItem id={RENTAL_INCLUDES_FORM_VALUES.ROAD_ASSISTANCE.ID}>
                    {({ value, onChange }: FormItemChildren) => (
                      <Checkbox
                        checked={value}
                        onChange={onChange}
                        variant="bcrm"
                        borderVariant="grey"
                        size="newFontsSecondary"
                        label={
                          RENTAL_INCLUDES_FORM_VALUES.ROAD_ASSISTANCE.LABEL
                        }
                      />
                    )}
                  </FormItem>
                </div>
              </Row>
            </div>
            <Typography Tag="h4" name="body1WBold" className={styles.subtitle}>
              {!isUmlimitedMileage ? EXCEPTIONS : MILEAGE}
            </Typography>
            {!isUmlimitedMileage ? (
              <>
                <div className={styles.info}>
                  <InfoIcon color="blue700" size="medium" />
                  <Typography name="body1WMedium" color="blue700">
                    {RENTAL_INCLUDES_LIMIT}
                  </Typography>
                </div>
                <FormItem id={RENTAL_INCLUDES_FORM_VALUES.DETAILS.ID}>
                  {({ value, onChange, onBlur }: FormItemChildren) => (
                    <Exception
                      values={value}
                      onChange={onChange}
                      onChangeErrors={handleChangeError}
                      error={exceptionsErrors}
                      onBlur={onBlur}
                    />
                  )}
                </FormItem>
              </>
            ) : (
              <Typography
                name="body2WMedium"
                Tag="div"
                className={styles['default-description']}
              >
                {RENTAL_INCLUDES_DEFAULT_MESSAGE}
                <Button
                  variant="link"
                  onClick={handleOpenExceptions}
                  label={EXCEPTIONS_BUTTON}
                  typographyName="body2WMedium"
                />
              </Typography>
            )}
            <ActionButtons
              disabled={data && !isDirty}
              onCancel={data && reload}
              loading={
                newTermConditionRequest.loading ||
                updateTermConditionRequest.loading
              }
            />
          </>
        )}
      </Form>
    </Block>
  )
}
