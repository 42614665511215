import React, { useEffect, useState } from 'react'
import { FilterItemProps, ItemData } from './types'
import { Typography } from 'ui/atoms/Typography'
import { Checkbox } from '@frontend/design_system'
import { ListChildComponentProps } from 'react-window'
import styles from './styles.module.scss'

export const FilterItem: React.FC<ListChildComponentProps<ItemData>> = ({
  data,
  index,
  style,
}: FilterItemProps) => {
  const { selected, onClick, value, counter } = data?.items[index]

  const [isChecked, setIsChecked] = useState(selected)

  useEffect(() => {
    setIsChecked(!!selected)
  }, [selected])

  const handleClick = (isChecked: boolean) => {
    onClick()
    setIsChecked(!isChecked)
  }

  return (
    <div style={style} className={styles.item}>
      <Checkbox
        checked={isChecked}
        onChange={handleClick}
        borderVariant="grey"
        labelColor="grey500"
        dataTestId="filter-item"
        label={
          <>
            <Typography
              className={styles['item-value']}
              name="Button2"
              color={isChecked ? 'blue700' : 'blackMaster'}
            >
              {value}
            </Typography>
            <Typography
              className={styles.counter}
              name="Subtitle4"
              color="grey400"
            >
              {counter}
            </Typography>
          </>
        }
      />
    </div>
  )
}
