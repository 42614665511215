import { CAR_CLASS_MODAL, RATES, ADD_CAR_MODAL } from 'texts/rateDetails'
import { PlusIcon } from 'ui/icons'
import notFoundSrc from 'assets/img/notFound.png'
import { useEffect, useMemo, useState } from 'react'
import { SideModal } from 'ui/molecules/SideModal'
import { useParams, useNavigate } from 'react-router-dom'
import { TipCarClass } from './components/TipCarClass'
import { URLS } from 'constants/urls'
import { joinClasses } from 'utils/joinClasses'
import { CarClassAddModalProps, CarItemType } from './types'
import { SeasonRange, PriceListRate } from 'api/fleet/types'
import {
  OriginButton,
  Checkbox,
  Typography,
  Input,
  LazyImage,
} from '@frontend/design_system'
import { ITEM_NOT_FOUND } from 'texts/uiTexts'
import styles from './styles.module.scss'

export const CarClassAddModal = ({
  tableContent,
  handleChangeContent,
  dailyRangesId,
  carClasses,
  disabled,
  rates,
}: CarClassAddModalProps) => {
  const [isModalOpen, setModalOpen] = useState(false)
  const [cars, setCars] = useState<CarItemType[]>([])
  const [search, setSearch] = useState('')
  const [allSelected, setAllSelected] = useState(false)
  const params = useParams()
  const navigate = useNavigate()

  const handleNavigate = () => {
    navigate(`${URLS.FLEET}${URLS.VEHICLES}/${params.companyId}`)
  }

  useEffect(() => {
    setCars(cars.sort((a, b) => Number(b.isChecked) - Number(a.isChecked)))
  }, [cars])

  useEffect(() => {
    const carsItems = carClasses.map((el) => ({
      name: `${el.carClass} - ${el.carName}`,
      isChecked: false,
      class: el.carClass,
      model: el.carName,
    }))

    setCars(carsItems)
  }, [carClasses])

  const handleOpenModal = () => {
    setModalOpen(true)
  }
  const handleCloseModal = () => setModalOpen(false)

  const checkedCarClasses = useMemo(
    () => cars.filter((item) => item.isChecked),
    [cars]
  )

  const filteredCarClasses = useMemo(() => {
    if (search) {
      const filtered = cars.filter((item) =>
        item.name.toLowerCase().includes(search.toLowerCase())
      )
      return filtered
    }
    return cars
  }, [search, cars])

  const handleCheckboxChange = (id: string) => {
    setCars((prevItems) =>
      prevItems.map((item) => {
        if (item.class === id) {
          return {
            ...item,
            isChecked: !item.isChecked,
          }
        }
        return item
      })
    )
  }

  const handleAllCheckbox = () => {
    setCars((prevItems) =>
      prevItems.map((item) => ({
        ...item,
        isChecked: !allSelected,
      }))
    )
  }

  const handleClickTips = () => {
    const checkIsNeedToChange = (item: CarItemType) =>
      checkedCarClasses.find(
        (checkedItem) => checkedItem.class === item.class
      ) && item.class !== checkedCarClasses[0].class

    setCars((prevItems) =>
      prevItems.map((item) => {
        if (checkIsNeedToChange(item)) {
          return {
            ...item,
            isChecked: false,
          }
        }
        return item
      })
    )
  }

  const addNewRanges = () => {
    if (!rates?.seasonRanges || !dailyRangesId) {
      return []
    }
    const newRates: PriceListRate[] = []
    rates?.seasonRanges.forEach((item: SeasonRange) => {
      dailyRangesId?.forEach((el) =>
        newRates.push({
          seasonRangeId: Number(item.seasonRangeId),
          dailyRangeId: Number(el),
          price: 0,
        })
      )
    })
    return newRates
  }

  const handleSubmit = () => {
    const newData = checkedCarClasses.map((el: CarItemType) => ({
      carClass: el.class,
      carName: el.model,
      carType: '',
      rates: addNewRanges(),
    }))
    handleChangeContent([...tableContent, ...newData])

    handleCloseModal()
  }

  useEffect(() => {
    setAllSelected(cars.length === checkedCarClasses.length)
  }, [checkedCarClasses])

  return (
    <>
      <div className={joinClasses(styles.button, [styles.disabled, disabled])}>
        <OriginButton
          size="small"
          typographyName="Button2"
          variant="secondary-border"
          label={RATES.ADD_CAR_MODEL}
          disabled={disabled}
          onClick={handleOpenModal}
          iconRight={<PlusIcon color={disabled ? 'grey500' : 'blue700'} />}
        />
      </div>
      {isModalOpen && (
        <SideModal onClose={handleCloseModal} title={RATES.ADD_CAR_MODEL}>
          <div className={styles['modal-info-content']}>
            {cars.length ? (
              <>
                <div className={styles['modal-info-header']}>
                  <div
                    className={joinClasses(styles['modal-info-search'], [
                      styles['checked'],
                      !!checkedCarClasses.length,
                    ])}
                  >
                    <Input
                      size="large"
                      value={search}
                      onChange={(value: string) => {
                        setSearch(value)
                      }}
                      iconLeft="SearchIcon"
                      spaceForError="none"
                      placeholder={ADD_CAR_MODAL.INPUT_PLACEHOLDER}
                    />
                    {!!checkedCarClasses.length && (
                      <div className={styles['modal-info-tips']}>
                        {checkedCarClasses.length === cars.length ? (
                          <TipCarClass
                            title={ADD_CAR_MODAL.SELECTED_ADD}
                            onClick={handleAllCheckbox}
                          />
                        ) : (
                          <>
                            <TipCarClass
                              title={checkedCarClasses[0].model}
                              onClick={() =>
                                handleCheckboxChange(checkedCarClasses[0].class)
                              }
                            />
                            {checkedCarClasses.length > 1 && (
                              <TipCarClass
                                title={`+${checkedCarClasses.length - 1}`}
                                onClick={handleClickTips}
                              />
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className={joinClasses(styles['modal-info-body'], [
                    styles['checked'],
                    !!checkedCarClasses.length,
                  ])}
                >
                  {!search && (
                    <div className={styles['modal-info-list-item-top']}>
                      <Checkbox
                        checked={allSelected}
                        onChange={handleAllCheckbox}
                        variant="primary"
                        borderVariant="grey"
                        size="secondary"
                      />
                      <Typography
                        name="Button2"
                        className={styles['modal-info-list-item-title']}
                        color={allSelected ? 'blue700' : 'grey900Master'}
                        onClick={handleAllCheckbox}
                      >
                        {ADD_CAR_MODAL.ALL}
                      </Typography>
                    </div>
                  )}
                  {filteredCarClasses.length ? (
                    filteredCarClasses.map((item) => (
                      <div
                        className={styles['modal-info-list-item']}
                        key={item.name}
                      >
                        <Checkbox
                          checked={item.isChecked}
                          onChange={() => handleCheckboxChange(item.class)}
                          variant="primary"
                          borderVariant="grey"
                          size="secondary"
                        />
                        <Typography
                          name="Button2"
                          className={styles['modal-info-list-item-title']}
                          color={item.isChecked ? 'blue700' : 'grey900Master'}
                          onClick={() => handleCheckboxChange(item.class)}
                        >
                          {item.name}
                        </Typography>
                      </div>
                    ))
                  ) : (
                    <div className={styles['not-found']}>
                      <Typography name="Button2" color="blueSecondary">
                        {ITEM_NOT_FOUND}
                      </Typography>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div className={styles['modal-info-not-found']}>
                <LazyImage src={notFoundSrc} width={201} height={127} />
                <Typography
                  name="subtitleWBold"
                  className={styles['modal-info-not-found-title']}
                >
                  {ADD_CAR_MODAL.NOT_FOUND_TITLE}
                </Typography>
                <Typography
                  name="body2WBold"
                  color="grey400"
                  className={styles['modal-info-not-found-description']}
                >
                  {ADD_CAR_MODAL.NOT_FOUND_DESCRIPTION}
                </Typography>
              </div>
            )}
            <div className={styles['modal-info-footer']}>
              {cars.length ? (
                <OriginButton
                  size="large"
                  label={CAR_CLASS_MODAL.ADD}
                  disabled={!checkedCarClasses.length}
                  onClick={handleSubmit}
                />
              ) : (
                <OriginButton
                  size="large"
                  label={ADD_CAR_MODAL.NOT_FOUND_BUTTON}
                  onClick={handleNavigate}
                />
              )}
            </div>
          </div>
        </SideModal>
      )}
    </>
  )
}
