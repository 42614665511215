import { Button } from '@frontend/design_system'
import { useDispatch } from 'react-redux'
import { resetSelectedFilters } from 'redux/filters/slice'
import { resetSorting } from 'redux/sorting/slice'
import { RESET_ALL } from 'texts/common'
import { ResetIcon } from 'ui/icons/ResetIcon'
import { ResetFiltersButtonProps } from './types'
import styles from './styles.module.scss'

export const ResetFiltersButton = ({
  selectedFiltersCount,
}: ResetFiltersButtonProps) => {
  const dispatch = useDispatch()

  const handleResetAllFilters = () => {
    dispatch(resetSelectedFilters())
    dispatch(resetSorting())
  }

  return selectedFiltersCount ? (
    <div className={styles['button-wrapper']}>
      <Button
        label={RESET_ALL}
        variant="secondary"
        typographyName="Subtitle3"
        onClick={handleResetAllFilters}
        dataTestId="reset-filters-button"
        size="small"
        iconLeft={<ResetIcon color="blue700" size="small" />}
      />
    </div>
  ) : null
}
