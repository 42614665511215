import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { RefreshIcon } from 'ui/icons'
import { Pagination } from '@frontend/design_system'
import { Table } from 'ui/molecules/Table'
import { MENU_NAMES } from 'texts/menuNames'
import {
  COMPANY_ID_INDEX,
  COMPANY_NAME_INDEX,
  COUNTRY_INDEX,
  headItems,
} from './constants'
import { Confirmation } from 'ui/molecules/Confirmation'
import { profileApi, ProfileApiTypes } from 'api'
import { PAGINATION_DROPDOWN_LABEL } from 'texts/uiTexts'
import {
  PROFILE_ARCHIVE,
  PROFILE_ARCHIVE_CONFIRMATION,
} from 'texts/profileArchive'
import { URLS } from 'constants/urls'
import { Container } from 'ui/molecules/Container'
import { Company } from 'api/profile/types'
import {
  DEFAULT_INITIAL_PAGE,
  DEFAULT_INITIAL_PAGE_SIZE,
} from 'constants/pagination'
import { useApiRequest } from 'hooks/useApiRequest'
import { TableHeadProps } from 'ui/molecules/Table/TableHead/types'
import { useFilterSettings } from 'hooks/useFilterSettings'
import { PROFILE_ARCHIVE_FILTER_KEY } from 'constants/filters'
import { sortingSelector } from 'redux/sorting/selectors'
import {
  filterItemsSelector,
  selectedFiltersCountSelector,
  selectedFiltersSelector,
} from 'redux/filters/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { prepareFiltersForReq, prepareSortingForReq } from 'utils/table'
import { resetSelectedFilters, setFilterItems } from 'redux/filters/slice'
import { prepareFilterItems } from 'utils/filters'
import { ResetFiltersButton } from 'ui/components/ResetFiltersButton'
import styles from './styles.module.scss'

export const ArchiveCompanyList = () => {
  const [page, setPage] = useState(DEFAULT_INITIAL_PAGE)
  const [pageSize, setPageSize] = useState(DEFAULT_INITIAL_PAGE_SIZE)
  const [companyList, setCompanyList] =
    useState<ProfileApiTypes.GetCompanyListResponse | null>(null)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [selectedCompany, setSelectedCompany] = useState<Company>()
  const sortingData = useSelector(sortingSelector)
  const selectedFiltersCount = useSelector(selectedFiltersCountSelector)
  const selectedFilters = useSelector(selectedFiltersSelector)
  const filterItems = useSelector(filterItemsSelector)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { apiRequest } = useApiRequest(
    (id) => profileApi.restoreCompany(id),
    '',
    true
  )

  const getFilterSettings = useFilterSettings(
    PROFILE_ARCHIVE_FILTER_KEY,
    setPage
  )

  const getCompanyListRequest = useApiRequest(() =>
    profileApi.getCompanyList(
      page - 1,
      Number(pageSize),
      true,
      prepareFiltersForReq(
        selectedFilters.profileArchive,
        selectedFiltersCount.profileArchive,
        PROFILE_ARCHIVE_FILTER_KEY
      ),
      prepareSortingForReq(
        sortingData.profileArchive,
        PROFILE_ARCHIVE_FILTER_KEY
      )
    )
  )

  const getProfileFiltersRequest = useApiRequest(() =>
    profileApi.getProfileListFilters(true)
  )

  const fetchCompanyList = async () => {
    const companyListResponse = await getCompanyListRequest.apiRequest()
    if (companyListResponse) {
      setCompanyList(companyListResponse.data)
    }
  }

  useEffect(() => {
    fetchCompanyList()
  }, [page, pageSize, sortingData, selectedFiltersCount.profileArchive])

  const fetchFilters = async () => {
    const profileArchiveFilters = await getProfileFiltersRequest.apiRequest()

    if (profileArchiveFilters) {
      dispatch(
        setFilterItems({
          items: prepareFilterItems(profileArchiveFilters.data),
          key: PROFILE_ARCHIVE_FILTER_KEY,
        })
      )
    }
  }

  useEffect(() => {
    !selectedFiltersCount.profileArchive && fetchFilters()
  }, [selectedFiltersCount.profileArchive])

  const handleChange = (pageNumber: number) => {
    setPage(pageNumber)
  }

  const changePageSize = (value: string) => {
    setPageSize(value)
    setPage(DEFAULT_INITIAL_PAGE)
  }

  const handleRestore = (company: Company) => {
    setSelectedCompany(company)
    setIsConfirmationModalOpen(true)
  }

  const handleConfirm = async (id: number) => {
    const response = await apiRequest(id)

    if (response) {
      dispatch(resetSelectedFilters())
      fetchCompanyList()
      return true
    } else {
      setIsConfirmationModalOpen(false)
    }
  }

  const handleModalClose = () => {
    setIsConfirmationModalOpen(false)
  }

  const handleRowClick = (id: number) => {
    navigate(`${URLS.PROFILE}${URLS.ARCHIVE}/${id}`)
  }

  const handleRedirectToSupplier = () => {
    if (selectedCompany?.id) {
      navigate(`${URLS.PROFILE}${URLS.PROFILE_LIST}/${selectedCompany.id}/edit`)
    }
  }

  const formattedBodyItems = companyList?.companies.pageItems?.map(
    (company) => ({
      items: [
        company.id,
        company.name,
        company.country,
        <RefreshIcon
          className={styles['refresh-icon']}
          color="blue700"
          size="small"
          onClick={(e) => {
            e.stopPropagation()
            handleRestore(company)
          }}
        />,
      ],
      onClick: () => handleRowClick(company.id),
    })
  )

  const formattedHeadItems = useMemo(() => {
    const newHeadItems: TableHeadProps['item'][] = headItems.map((header) => ({
      value: header,
    }))
    newHeadItems[COMPANY_ID_INDEX].filterSettings =
      getFilterSettings('companyIds')
    newHeadItems[COMPANY_NAME_INDEX].filterSettings =
      getFilterSettings('companyNames')
    newHeadItems[COUNTRY_INDEX].filterSettings = getFilterSettings('countries')
    return newHeadItems
  }, [filterItems.profileArchive, getFilterSettings])

  return (
    <div>
      {isConfirmationModalOpen && selectedCompany && (
        <Confirmation
          confirmModalProps={{
            question: `${PROFILE_ARCHIVE_CONFIRMATION.CONFIRMATION_MODAL.QUESTION} ${selectedCompany?.name}?`,
            onConfirm: () => handleConfirm(selectedCompany.id),
            onClose: handleModalClose,
            title: `${PROFILE_ARCHIVE.RESTORE} ${selectedCompany?.name}?`,
          }}
          successModalProps={{
            onClose: handleModalClose,
            title: PROFILE_ARCHIVE_CONFIRMATION.SUCCESS_MODAL.TITLE,
            buttonLabel:
              PROFILE_ARCHIVE_CONFIRMATION.SUCCESS_MODAL.BUTTON_LABEL,
            onConfirm: handleRedirectToSupplier,
            subTitle: `${PROFILE_ARCHIVE_CONFIRMATION.SUCCESS_MODAL.SUPPLIER} ${selectedCompany?.name} ${PROFILE_ARCHIVE_CONFIRMATION.SUCCESS_MODAL.SUBTITLE}`,
          }}
        />
      )}
      <Container
        title={MENU_NAMES.PROFILE_ARCHIVE.NAME}
        tooltip={PROFILE_ARCHIVE.TOOLTIP}
        withCompanyName={false}
        loading={
          getCompanyListRequest.loading && !getCompanyListRequest.isLoaded
        }
      >
        <div className={styles.content}>
          <div className={styles.list}>
            <div className={styles.header}>
              <ResetFiltersButton
                selectedFiltersCount={selectedFiltersCount.profileArchive}
              />
            </div>
            <div className={styles.table}>
              {formattedBodyItems && (
                <Table
                  headItems={formattedHeadItems}
                  bodyItems={formattedBodyItems}
                  initItemsWidth={['151', '', '', '90']}
                />
              )}
            </div>
          </div>
          <footer className={styles.pagination}>
            <Pagination
              current={page}
              pageItemCount={companyList?.companies.pageItems.length}
              total={companyList?.companies?.totalItems || 0}
              pageSize={pageSize}
              changePageSize={changePageSize}
              changePage={handleChange}
              pageSizeLabel={PAGINATION_DROPDOWN_LABEL}
            />
          </footer>
        </div>
      </Container>
    </div>
  )
}
