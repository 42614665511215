import { Modal, Button } from '@frontend/design_system'
import { ConfirmModalProps } from './types'
import { Typography } from 'ui/atoms/Typography'
import styles from './styles.module.scss'

export const ConfirmModal = ({
  onClose,
  onDecline,
  onConfirm,
  question,
  title,
  confirmLabel = 'Confirm',
  declineLabel = 'Cancel',
}: ConfirmModalProps) => (
  <Modal
    headerSize="large"
    isDelimiter
    position="center-horizontal"
    onClose={onClose}
    title={title}
    dataTestId="confirmation-modal"
  >
    <div className={styles.content}>
      <Typography name="Button1" color="grey500">
        {question}
      </Typography>
      <div className={styles.buttons}>
        <div className={styles['button-wrapper']}>
          <Button
            label={declineLabel}
            variant="secondary-border"
            onClick={onDecline || onClose}
            size="large"
          />
        </div>
        <div className={styles['button-wrapper']}>
          <Button
            label={confirmLabel}
            onClick={onConfirm}
            size="large"
            dataTestId="confirmation-modal-confirm-button"
          />
        </div>
      </div>
    </div>
  </Modal>
)
