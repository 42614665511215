import { fleetApi, FleetApiTypes } from 'api'
import { breadcrumbs } from 'constants/breadcrumbs'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from 'redux/hooks'
import { isOperatorSelector, userDataSelector } from 'redux/login/selectors'
import { MENU_NAMES } from 'texts/menuNames'
import { VEHICLES, VEHICLES_DELETE_CONFIRMATION } from 'texts/vehicles'
import { Button } from 'ui/atoms/Button'
import { PlusIcon } from 'ui/icons'
import { TableTooltip } from 'ui/molecules/TableTooltip'
import { Confirmation } from 'ui/molecules/Confirmation'
import { Pagination, Typography } from '@frontend/design_system'
import { Table } from 'ui/molecules/Table'
import { Container } from 'ui/molecules/Container'
import { Vehicle } from './types'
import { headItems } from './constants'
import { useDispatch, useSelector } from 'react-redux'
import { setNotificationMessage } from 'redux/notifications/slice'
import {
  DEFAULT_INITIAL_PAGE,
  DEFAULT_INITIAL_PAGE_SIZE,
} from 'constants/pagination'
import { PAGINATION_DROPDOWN_LABEL, TOTAL } from 'texts/uiTexts'
import styles from './styles.module.scss'
import { useApiRequest } from 'hooks/useApiRequest'
import { GTM } from 'utils/gtm'
import { DATA_LAYER_EVENT } from 'constants/dataLayerEvents'
import { ApproveBtn } from 'ui/components/ApproveBtn'
import { ACCOUNT_STATUSES, REGISTRATION_STATUSES } from 'api/auth/constants'
import { PendingForApproval } from 'modules/PendingForApproval'
import { prepareFiltersForReq, prepareSortingForReq } from 'utils/table'
import { sortingSelector } from 'redux/sorting/selectors'
import {
  filterItemsSelector,
  selectedFiltersCountSelector,
  selectedFiltersSelector,
} from 'redux/filters/selectors'
import { setFilterItems } from 'redux/filters/slice'
import { prepareFilterItems } from 'utils/filters'
import { CARS_FILTER_KEY, CARS_FILTERS_KEYS } from 'constants/filters'
import { TableHeadProps } from 'ui/molecules/Table/TableHead/types'
import { useFilterSettings } from 'hooks/useFilterSettings'
import { ResetFiltersButton } from 'ui/components/ResetFiltersButton'
import { useCompanyId } from 'hooks/useCompanyId'

export const Vehicles = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(DEFAULT_INITIAL_PAGE)
  const [pageSize, setPageSize] = useState(DEFAULT_INITIAL_PAGE_SIZE)
  const [vehiclesList, setVehiclesList] =
    useState<FleetApiTypes.GetVehiclesListResponse | null>(null)
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle>()
  const { accountStatus, sectionsStatuses } = useAppSelector(userDataSelector)
  const isOperator = useAppSelector(isOperatorSelector)
  const sortingData = useSelector(sortingSelector)
  const selectedFiltersCount = useSelector(selectedFiltersCountSelector)
  const selectedFilters = useSelector(selectedFiltersSelector)
  const filterItems = useSelector(filterItemsSelector)
  const isAccountApproved =
    isOperator || accountStatus === ACCOUNT_STATUSES.APPROVED

  const navigate = useNavigate()

  const breadcrumbsList = [breadcrumbs.profileList]

  const companyId = useCompanyId()
  const deleteVehicleRequest = useApiRequest((id) => fleetApi.deleteVehicle(id))
  const fetchVehiclesRequest = useApiRequest((companyId) =>
    fleetApi.getVehiclesList(
      page - 1,
      Number(pageSize),
      companyId,
      prepareFiltersForReq(
        selectedFilters.cars,
        selectedFiltersCount.cars,
        CARS_FILTER_KEY
      ),
      prepareSortingForReq(sortingData.cars, CARS_FILTER_KEY)
    )
  )

  const getFilterSettings = useFilterSettings(CARS_FILTER_KEY, setPage)

  const getVehiclesFiltersRequest = useApiRequest((companyId) =>
    fleetApi.getCarsFilters(companyId)
  )

  const handleChange = (pageNumber: number) => {
    setPage(pageNumber)
  }

  const changePageSize = (value: string) => {
    setPageSize(value)
    setPage(DEFAULT_INITIAL_PAGE)
  }

  const fetchVehicleRequest = async (companyId: number) => {
    const vehiclesListResponse = await fetchVehiclesRequest.apiRequest(
      companyId
    )
    if (vehiclesListResponse) {
      setVehiclesList(vehiclesListResponse.data)
    }
  }

  const fetchFilters = async () => {
    const getVehiclesFilters = await getVehiclesFiltersRequest.apiRequest(
      companyId
    )

    if (getVehiclesFilters) {
      dispatch(
        setFilterItems({
          items: prepareFilterItems(getVehiclesFilters.data),
          key: CARS_FILTER_KEY,
        })
      )
    }
  }

  useEffect(() => {
    !selectedFiltersCount.cars && isAccountApproved && fetchFilters()
  }, [selectedFiltersCount.cars, isAccountApproved])

  useEffect(() => {
    if (companyId && isAccountApproved) fetchVehicleRequest(Number(companyId))
  }, [
    page,
    pageSize,
    companyId,
    isAccountApproved,
    selectedFiltersCount,
    sortingData,
  ])

  const handleDeleteVehicle = (id: number) => {
    setConfirmationModalOpen(true)
    setSelectedVehicle(vehiclesList?.vehicles.pageItems[id])
  }

  const handleConfirm = async () => {
    if (selectedVehicle) {
      const response = await deleteVehicleRequest.apiRequest(
        selectedVehicle.uniqueId
      )
      if (response) {
        dispatch(
          setNotificationMessage({
            notificationMessage: VEHICLES_DELETE_CONFIRMATION.SUCCESS,
          })
        )
        GTM.dataLayer({
          event: DATA_LAYER_EVENT.CAR_REMOVED,
          car_type: selectedVehicle.groupName,
          car_sipp: selectedVehicle.sippCode,
        })
        fetchVehicleRequest(Number(companyId))
      }
      setConfirmationModalOpen(false)
    }
  }

  const handleModalClose = () => {
    setConfirmationModalOpen(false)
  }

  const handleRowClick = (id: string) => {
    navigate(`../vehicles/${companyId}/${id}/edit`)
  }

  const handleAddVehicle = () => navigate('add')

  useEffect(() => {
    if (
      sectionsStatuses?.carsStatus === REGISTRATION_STATUSES.INCOMPLETE &&
      accountStatus !== ACCOUNT_STATUSES.APPROVED &&
      isOperator
    ) {
      handleAddVehicle()
    }
  }, [sectionsStatuses])

  const formattedHeadItems = useMemo(() => {
    const keys = Object.keys(CARS_FILTERS_KEYS)
    const newHeadItems: TableHeadProps['item'][] = headItems.map(
      (header, i) => ({
        value: header,
        filterSettings: getFilterSettings(keys[i]),
      })
    )

    return newHeadItems
  }, [filterItems.cars, getFilterSettings])

  const formattedBodyItems = vehiclesList?.vehicles.pageItems?.map(
    (vehicle) => ({
      items: [
        <div className={styles.vehicle}>
          <img src={vehicle.image} className={styles.image} />
          <Typography name="Subtitle7">{vehicle.name}</Typography>
        </div>,
        vehicle.companyClass,
        vehicle.sippCode,
        vehicle.groupName,
        <TableTooltip
          title={
            vehicle.hirePoints?.length ? vehicle.hirePoints?.join(', ') : ''
          }
          onClick={() => handleRowClick(vehicle.uniqueId)}
        />,
      ],
      onClick: () => handleRowClick(vehicle.uniqueId),
      removeRowClick: handleDeleteVehicle,
    })
  )

  if (!isAccountApproved) {
    return <PendingForApproval />
  }

  return (
    <div>
      {isConfirmationModalOpen && (
        <Confirmation
          confirmModalProps={{
            question: `${VEHICLES_DELETE_CONFIRMATION.CONFIRMATION_MODAL.QUESTION} ${selectedVehicle?.name}?`,
            onConfirm: handleConfirm,
            onClose: handleModalClose,
            title: `${VEHICLES.DELETE} ${selectedVehicle?.name} ${VEHICLES.MODEL}`,
          }}
        />
      )}
      <Container
        breadcrumbList={isOperator ? breadcrumbsList : []}
        currentPageLabel={MENU_NAMES.VEHICLES}
        title={MENU_NAMES.VEHICLES}
        tooltip={
          accountStatus === ACCOUNT_STATUSES.APPROVED
            ? VEHICLES.TOOLTIP_CONTENT
            : undefined
        }
        withNavigation={isOperator}
        rightBlock={(id: string) => <ApproveBtn companyId={id} />}
        loading={fetchVehiclesRequest.loading && !fetchVehiclesRequest.isLoaded}
      >
        <div className={styles.list}>
          <div className={styles.header}>
            <Typography name="Subtitle2">{`${TOTAL}: ${vehiclesList?.vehicles.totalItems}`}</Typography>
            <div className={styles['action-buttons']}>
              <ResetFiltersButton
                selectedFiltersCount={selectedFiltersCount.cars}
              />
              <div className={styles['button-wrapper']}>
                <Button
                  size="small"
                  typographyName="Button2"
                  variant="outline"
                  label={VEHICLES.ADD_BUTTON}
                  onClick={handleAddVehicle}
                  iconPosition="right"
                  icon={<PlusIcon color="blue700" />}
                />
              </div>
            </div>
          </div>
          {formattedBodyItems && (
            <div className={styles.table}>
              <Table
                headItems={formattedHeadItems}
                bodyItems={formattedBodyItems}
                initItemsWidth={['262', '170', '108', '130', '183']}
              />
            </div>
          )}
        </div>
        <footer className={styles.pagination}>
          <Pagination
            current={page}
            pageItemCount={vehiclesList?.vehicles.pageItems.length}
            total={vehiclesList?.vehicles.totalItems || 0}
            pageSize={pageSize}
            changePageSize={changePageSize}
            changePage={handleChange}
            pageSizeLabel={PAGINATION_DROPDOWN_LABEL}
          />
        </footer>
      </Container>
    </div>
  )
}
