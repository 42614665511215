import { useEffect, useMemo, useState } from 'react'
import { CONTACT_INFORMATION_FORM_VALUES } from 'texts/profileDetails'
import { Input } from 'ui/atoms/Input'
import { FormItem, Select } from '@frontend/design_system'
import { ContactInformationFormProps } from './types'
import { getValidationRules } from 'utils/getValidationRules'
import { Phone } from 'ui/molecules/Phone'
import countryOfResidence from 'constants/countryOfResidence'
import { contactInformationValuesSetting as formValuesSetting } from './constants'
import { getSmallFlagWebpSrc } from 'utils/flags'
import { PHONE_NUMBER_LABELS } from 'texts/uiTexts'
import { Rule } from 'ui/molecules/Form/components/FormItem/types'
import { useApiRequest } from 'hooks/useApiRequest'
import { referencesApi } from 'api'
import { ItemType } from 'ui/atoms/Select/types'
import { FormItemChildren, FormValuesSetting } from 'types/form'
import { REGISTRATION_STATUSES } from 'api/auth/constants'
import { useAppSelector } from 'redux/hooks'
import { userDataSelector } from 'redux/login/selectors'
import styles from './styles.module.scss'

export const ContactInformationForm = ({
  role,
  countries,
  readOnly,
  selectedCountryId,
}: ContactInformationFormProps) => {
  const [citySelectValues, setCitySelectValues] = useState<ItemType[]>([])

  const { apiRequest } = useApiRequest((selectedCountryId) =>
    referencesApi.getCities(selectedCountryId)
  )

  const { sectionsStatuses } = useAppSelector(userDataSelector)
  const isProfileIncomplete =
    sectionsStatuses?.profileStatus === REGISTRATION_STATUSES.INCOMPLETE

  const countrySelectValues = useMemo(
    () =>
      countries.items.map((item) => ({
        label: item.name,
        value: item.id,
        renderLabel: () => (
          <div className={styles['select-list-item-content']}>
            <img
              src={getSmallFlagWebpSrc(item.meta.CountryAlpha2Code)}
              alt="flag"
              className={styles.flag}
              width="20px"
            />
            {item.name}
          </div>
        ),
      })),
    [countries]
  )

  useEffect(() => {
    if (selectedCountryId) {
      const getCities = async () => {
        const response = await apiRequest(selectedCountryId)
        if (response) {
          const citySelectValues = response.data.items.map((item) => ({
            label: item.name,
            value: item.id,
          }))
          setCitySelectValues(citySelectValues)
        }
      }
      getCities()
    }
  }, [selectedCountryId])

  const validationRules = (field: FormValuesSetting['key']) =>
    useMemo(() => getValidationRules(field, role), [role])

  const phoneCorRules: Rule[] = useMemo(
    () => [
      {
        type: 'required',
        message: CONTACT_INFORMATION_FORM_VALUES.PHONE_COR.REQUIRED_MESSAGE,
      },
    ],
    []
  )

  const emptyRules = useMemo(() => [], [])

  const getCor = (country: ItemType) => {
    const countryCode = countries.items.find((el) => el.name === country.label)
      ?.meta.CountryAlpha2Code
    const selectedCountry = countryOfResidence.find(
      (el) => el.code === countryCode
    )
    return selectedCountry
  }

  const isDisabledField = (name: string) =>
    readOnly || (!isProfileIncomplete && formValuesSetting[name].disabled[role])

  return (
    <>
      <div className={styles['form-items-row']}>
        <FormItem
          id={CONTACT_INFORMATION_FORM_VALUES.NAME.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.name)}
        >
          {({ value, error, onChange }: FormItemChildren) => (
            <Input
              value={value}
              placeholder={CONTACT_INFORMATION_FORM_VALUES.NAME.PLACEHOLDER}
              size="large"
              error={error}
              disabled={readOnly || formValuesSetting.name.disabled[role]}
              spaceForError="auto"
              onChange={onChange}
              label={CONTACT_INFORMATION_FORM_VALUES.NAME.LABEL}
              isRequired={formValuesSetting.name.required[role]}
            />
          )}
        </FormItem>
        <FormItem
          id={CONTACT_INFORMATION_FORM_VALUES.CONTACT_PERSON_NAME.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.contactPersonName)}
        >
          {({ value, error, onChange }: FormItemChildren) => (
            <Input
              value={value}
              placeholder={
                CONTACT_INFORMATION_FORM_VALUES.CONTACT_PERSON_NAME.PLACEHOLDER
              }
              size="large"
              error={error}
              spaceForError="auto"
              disabled={
                readOnly || formValuesSetting.contactPersonName.disabled[role]
              }
              onChange={onChange}
              label={CONTACT_INFORMATION_FORM_VALUES.CONTACT_PERSON_NAME.LABEL}
              isRequired={formValuesSetting.contactPersonName.required[role]}
            />
          )}
        </FormItem>
      </div>
      <div className={styles['form-items-row']}>
        <FormItem
          id={CONTACT_INFORMATION_FORM_VALUES.EMAIL.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.mainMail)}
        >
          {({ value, error, onChange }: FormItemChildren) => (
            <Input
              value={value}
              placeholder={CONTACT_INFORMATION_FORM_VALUES.EMAIL.PLACEHOLDER}
              size="large"
              error={error}
              spaceForError="auto"
              disabled={readOnly || formValuesSetting.mainMail.disabled[role]}
              onChange={onChange}
              label={CONTACT_INFORMATION_FORM_VALUES.EMAIL.LABEL}
              isRequired={formValuesSetting.mainMail.required[role]}
            />
          )}
        </FormItem>
        <FormItem
          id={CONTACT_INFORMATION_FORM_VALUES.BRAND_NAME.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.brandName)}
        >
          {({ value, error, onChange }: FormItemChildren) => (
            <Input
              value={value}
              placeholder={
                CONTACT_INFORMATION_FORM_VALUES.BRAND_NAME.PLACEHOLDER
              }
              size="large"
              error={error}
              spaceForError="auto"
              disabled={isDisabledField('brandName')}
              onChange={onChange}
              label={CONTACT_INFORMATION_FORM_VALUES.BRAND_NAME.LABEL}
              isRequired={formValuesSetting.brandName.required[role]}
            />
          )}
        </FormItem>
      </div>
      <div className={styles['form-items-row']}>
        <FormItem
          id={CONTACT_INFORMATION_FORM_VALUES.COUNTRY.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.country)}
        >
          {({ value, error, onChange, onChangeField }: FormItemChildren) => (
            <Select
              size="large"
              error={error}
              placeholder={CONTACT_INFORMATION_FORM_VALUES.COUNTRY.PLACEHOLDER}
              onChange={(value: string, item: ItemType) => {
                onChange(value)
                onChangeField(
                  getCor(item),
                  CONTACT_INFORMATION_FORM_VALUES.PHONE_COR.ID
                )
                onChangeField('', CONTACT_INFORMATION_FORM_VALUES.CITY.ID)
              }}
              label={CONTACT_INFORMATION_FORM_VALUES.COUNTRY.LABEL}
              isRequired={formValuesSetting.country.required[role]}
              items={countrySelectValues}
              disabled={isDisabledField('country')}
              selectedValue={value}
              search={{
                placeholder:
                  CONTACT_INFORMATION_FORM_VALUES.COUNTRY.SEARCH_PLACEHOLDER,
              }}
            />
          )}
        </FormItem>
        <FormItem
          id={CONTACT_INFORMATION_FORM_VALUES.CITY.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.city)}
        >
          {({ value, error, onChange }: FormItemChildren) => (
            <Select
              selectedValue={value}
              placeholder={CONTACT_INFORMATION_FORM_VALUES.CITY.PLACEHOLDER}
              size="large"
              error={error}
              items={citySelectValues}
              onChange={onChange}
              disabled={readOnly || !selectedCountryId}
              label={CONTACT_INFORMATION_FORM_VALUES.CITY.LABEL}
              isRequired={formValuesSetting.city.required[role]}
              search={{
                placeholder:
                  CONTACT_INFORMATION_FORM_VALUES.CITY.SEARCH_PLACEHOLDER,
              }}
            />
          )}
        </FormItem>
      </div>
      <div className={styles['form-items-row']}>
        <FormItem
          id={CONTACT_INFORMATION_FORM_VALUES.STATE.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.state)}
        >
          {({ value, error, onChange }: FormItemChildren) => (
            <Input
              value={value}
              placeholder={CONTACT_INFORMATION_FORM_VALUES.STATE.PLACEHOLDER}
              size="large"
              error={error}
              spaceForError="auto"
              onChange={onChange}
              disabled={readOnly}
              label={CONTACT_INFORMATION_FORM_VALUES.STATE.LABEL}
              isRequired={formValuesSetting.state.required[role]}
            />
          )}
        </FormItem>
        <FormItem
          id={CONTACT_INFORMATION_FORM_VALUES.STREET.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.street)}
        >
          {({ value, error, onChange }: FormItemChildren) => (
            <Input
              value={value}
              placeholder={CONTACT_INFORMATION_FORM_VALUES.STREET.PLACEHOLDER}
              size="large"
              error={error}
              spaceForError="auto"
              onChange={onChange}
              disabled={readOnly}
              label={CONTACT_INFORMATION_FORM_VALUES.STREET.LABEL}
              isRequired={formValuesSetting.street.required[role]}
            />
          )}
        </FormItem>
      </div>
      <div className={styles['form-items-row']}>
        <FormItem
          id={CONTACT_INFORMATION_FORM_VALUES.BUILDING.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.building)}
        >
          {({ value, error, onChange }: FormItemChildren) => (
            <Input
              value={value}
              placeholder={CONTACT_INFORMATION_FORM_VALUES.BUILDING.PLACEHOLDER}
              size="large"
              error={error}
              spaceForError="auto"
              onChange={onChange}
              disabled={readOnly}
              label={CONTACT_INFORMATION_FORM_VALUES.BUILDING.LABEL}
              isRequired={formValuesSetting.building.required[role]}
            />
          )}
        </FormItem>
        <FormItem
          id={CONTACT_INFORMATION_FORM_VALUES.POSTAL_CODE.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.postalCode)}
        >
          {({ value, error, onChange }: FormItemChildren) => (
            <Input
              value={value}
              placeholder={
                CONTACT_INFORMATION_FORM_VALUES.POSTAL_CODE.PLACEHOLDER
              }
              size="large"
              error={error}
              spaceForError="auto"
              onChange={onChange}
              disabled={readOnly}
              label={CONTACT_INFORMATION_FORM_VALUES.POSTAL_CODE.LABEL}
              isRequired={formValuesSetting.postalCode.required[role]}
            />
          )}
        </FormItem>
      </div>
      <div className={styles['form-items-row']}>
        <FormItem
          id={CONTACT_INFORMATION_FORM_VALUES.PHONE.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.phone)}
        >
          {({
            value: number,
            onChange: changeNumber,
            error,
          }: FormItemChildren) => (
            <FormItem
              id={CONTACT_INFORMATION_FORM_VALUES.PHONE_COR.ID}
              rules={number?.length ? phoneCorRules : emptyRules}
            >
              {({
                value: cor,
                onChange: changeCor,
                error: phoneError,
              }: FormItemChildren) => (
                <Phone
                  label={CONTACT_INFORMATION_FORM_VALUES.PHONE.LABEL}
                  number={number}
                  changeNumber={changeNumber}
                  cor={cor}
                  changeCOR={changeCor}
                  corList={countryOfResidence}
                  locales={{
                    ofLabel: PHONE_NUMBER_LABELS.OF,
                    searchPlaceholder: PHONE_NUMBER_LABELS.SEARCH,
                    save: PHONE_NUMBER_LABELS.SAVE,
                  }}
                  spaceForError="auto"
                  error={error || (number?.length ? phoneError : undefined)}
                  placeholder={
                    CONTACT_INFORMATION_FORM_VALUES.PHONE.PLACEHOLDER
                  }
                  disabled={readOnly}
                />
              )}
            </FormItem>
          )}
        </FormItem>
        <FormItem
          id={CONTACT_INFORMATION_FORM_VALUES.URL.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.website)}
        >
          {({ value, error, onChange }: FormItemChildren) => (
            <Input
              value={value}
              placeholder={CONTACT_INFORMATION_FORM_VALUES.URL.PLACEHOLDER}
              size="large"
              error={error}
              spaceForError="auto"
              onChange={onChange}
              disabled={readOnly}
              label={CONTACT_INFORMATION_FORM_VALUES.URL.LABEL}
            />
          )}
        </FormItem>
      </div>
    </>
  )
}
