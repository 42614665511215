import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { URLS } from 'constants/urls'
import { ArchiveIcon, PlusIcon } from 'ui/icons'
import { Pagination, Typography, Button } from '@frontend/design_system'
import { Table } from 'ui/molecules/Table'
import { MENU_NAMES } from 'texts/menuNames'
import { profileApi, ProfileApiTypes } from 'api'
import { ActionItems } from 'ui/atoms/ActionItems'
import { useAppDispatch } from 'redux/hooks'
import { Container } from 'ui/molecules/Container'
import { Confirmation } from 'ui/molecules/Confirmation'
import {
  COMPANY_ID_INDEX,
  COMPANY_NAME_INDEX,
  COUNTRY_INDEX,
  headItems,
  STATUS_INDEX,
} from './constants'
import { PROFILE_LIST, PROFILE_LIST_CONFIRMATION } from 'texts/profileList'
import { Company } from 'api/profile/types'
import {
  DEFAULT_INITIAL_PAGE,
  DEFAULT_INITIAL_PAGE_SIZE,
} from 'constants/pagination'
import { PAGINATION_DROPDOWN_LABEL } from 'texts/uiTexts'
import { useApiRequest } from 'hooks/useApiRequest'
import { deactivateProfileSubtitle } from 'texts/profileConfirmModal'
import styles from './styles.module.scss'
import { joinClasses } from 'utils/joinClasses'
import { useFilterSettings } from 'hooks/useFilterSettings'
import { PROFILE_LIST_FILTER_KEY } from 'constants/filters'
import { sortingSelector } from 'redux/sorting/selectors'
import { useSelector } from 'react-redux'
import { resetSelectedFilters, setFilterItems } from 'redux/filters/slice'
import { prepareFiltersForReq, prepareSortingForReq } from 'utils/table'
import {
  filterItemsSelector,
  selectedFiltersCountSelector,
  selectedFiltersSelector,
} from 'redux/filters/selectors'
import { useCompanyInfo } from 'ui/hooks/useCompanyInfo'
import { useNavigationItems } from 'ui/hooks/useNavigationItems'
import { resetSectionStatuses } from 'redux/login/slice'
import { TableHeadProps } from 'ui/molecules/Table/TableHead/types'
import { prepareFilterItems } from 'utils/filters'
import { ResetFiltersButton } from 'ui/components/ResetFiltersButton'

export const ProfileList = () => {
  const [page, setPage] = useState(DEFAULT_INITIAL_PAGE)
  const [pageSize, setPageSize] = useState(DEFAULT_INITIAL_PAGE_SIZE)
  const [companyList, setCompanyList] =
    useState<ProfileApiTypes.GetCompanyListResponse | null>(null)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [selectedCompany, setSelectedCompany] = useState<Company>()
  const sortingData = useSelector(sortingSelector)
  const selectedFiltersCount = useSelector(selectedFiltersCountSelector)
  const selectedFilters = useSelector(selectedFiltersSelector)
  const filterItems = useSelector(filterItemsSelector)
  const getFilterSettings = useFilterSettings(PROFILE_LIST_FILTER_KEY, setPage)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const navigationItems = useNavigationItems()
  const { getCompanyInfo } = useCompanyInfo(false)
  const { apiRequest } = useApiRequest(
    (id) => profileApi.closeCompany(id),
    undefined,
    true
  )

  const getCompanyListRequest = useApiRequest(() =>
    profileApi.getCompanyList(
      page - 1,
      Number(pageSize),
      false,
      prepareFiltersForReq(
        selectedFilters.profileList,
        selectedFiltersCount.profileList,
        PROFILE_LIST_FILTER_KEY
      ),
      prepareSortingForReq(sortingData.profileList, PROFILE_LIST_FILTER_KEY)
    )
  )

  const getProfileFiltersRequest = useApiRequest(() =>
    profileApi.getProfileListFilters(false)
  )

  const fetchProfileList = async () => {
    const companyListResponse = await getCompanyListRequest.apiRequest()
    if (companyListResponse) {
      setCompanyList(companyListResponse.data)
    }
  }

  useEffect(() => {
    fetchProfileList()
  }, [page, pageSize, sortingData, selectedFiltersCount.profileList])

  const fetchFilters = async () => {
    const profileListFilters = await getProfileFiltersRequest.apiRequest()

    if (profileListFilters) {
      dispatch(
        setFilterItems({
          items: prepareFilterItems(profileListFilters.data),
          key: PROFILE_LIST_FILTER_KEY,
        })
      )
    }
  }

  useEffect(() => {
    dispatch(resetSectionStatuses())
  }, [])

  useEffect(() => {
    !selectedFiltersCount.profileList && fetchFilters()
  }, [selectedFiltersCount.profileList])

  const handleChange = (pageNumber: number) => {
    setPage(pageNumber)
  }

  const changePageSize = (value: string) => {
    setPageSize(value)
    setPage(DEFAULT_INITIAL_PAGE)
  }

  const handleArchiveCompany = async (company: Company) => {
    setIsConfirmationModalOpen(true)
    setSelectedCompany(company)
  }

  const handleConfirm = async (id: number) => {
    const response = await apiRequest(id)
    if (!response) {
      setIsConfirmationModalOpen(false)
    } else {
      dispatch(resetSelectedFilters())
      fetchProfileList()
      return true
    }
  }

  const handleAddProfile = () => {
    navigate('add')
  }

  const handleModalClose = () => {
    setIsConfirmationModalOpen(false)
  }

  const handleRedirectToArchive = () => {
    navigate(`..${URLS.ARCHIVE}`)
  }

  const handleRowClick = (id: number) => {
    navigate(`${id}/edit`)
  }

  const handleActionItemClick = (url: string) => {
    navigate(url)
  }

  const formattedHeadItems = useMemo(() => {
    const newHeadItems: TableHeadProps['item'][] = headItems.map((header) => ({
      value: header,
    }))
    newHeadItems[COMPANY_ID_INDEX].filterSettings =
      getFilterSettings('companyIds')
    newHeadItems[COMPANY_NAME_INDEX].filterSettings =
      getFilterSettings('companyNames')
    newHeadItems[COUNTRY_INDEX].filterSettings = getFilterSettings('countries')
    newHeadItems[STATUS_INDEX].filterSettings = getFilterSettings('statuses')
    return newHeadItems
  }, [filterItems.profileList, getFilterSettings])

  const formattedBodyItems = useMemo(
    () =>
      companyList?.companies.pageItems?.map((company) => {
        const isStatusRegistered =
          company.accountStatus === 'Registered' ||
          company.accountStatus === 'Pending'
        return {
          items: [
            company.id,
            company.name,
            company.country,
            <Typography
              name="caption1WBold"
              className={joinClasses(
                styles.status,
                styles[company.accountStatus]
              )}
            >
              {company.accountStatus}
            </Typography>,
            <div className={styles['action-items']}>
              <ActionItems
                items={navigationItems.map(
                  ({ label, getPath, isDisabled }) => ({
                    value: label,
                    path: getPath(String(company.id)),
                    onClick: handleActionItemClick,
                    isDisabled: isStatusRegistered ? isDisabled : false,
                  })
                )}
                listItemWidth="200px"
                waitPreparation={() =>
                  isStatusRegistered ? getCompanyInfo(company.id) : null
                }
              />
              <ArchiveIcon
                color="blue700"
                className={styles['archive-icon']}
                dataTestId="archive-icon"
                onClick={(e) => {
                  e.stopPropagation()
                  handleArchiveCompany(company)
                }}
              />
            </div>,
          ],
          onClick: () => handleRowClick(company.id),
        }
      }),
    [selectedCompany, companyList, navigationItems]
  )

  return (
    <div>
      {isConfirmationModalOpen && selectedCompany && (
        <Confirmation
          confirmModalProps={{
            question: `${PROFILE_LIST_CONFIRMATION.CONFIRMATION_MODAL.QUESTION} ${selectedCompany?.name}?`,
            onConfirm: () => handleConfirm(selectedCompany.id),
            onClose: handleModalClose,
            title: `${PROFILE_LIST.ARCHIVE} ${selectedCompany.name}?`,
          }}
          successModalProps={{
            onClose: handleModalClose,
            title: PROFILE_LIST_CONFIRMATION.SUCCESS_MODAL.TITLE,
            buttonLabel: PROFILE_LIST_CONFIRMATION.SUCCESS_MODAL.BUTTON_LABEL,
            onConfirm: handleRedirectToArchive,
            subTitle: deactivateProfileSubtitle(selectedCompany?.name),
          }}
        />
      )}
      <Container
        title={MENU_NAMES.PROFILE_LIST.NAME}
        tooltip={PROFILE_LIST.TOOLTIP_CONTENT}
        withCompanyName={false}
        loading={
          getCompanyListRequest.loading && !getCompanyListRequest.isLoaded
        }
      >
        <div className={styles.list}>
          <div className={styles.header}>
            <ResetFiltersButton
              selectedFiltersCount={selectedFiltersCount.profileList}
            />
            <div className={styles.button}>
              <Button
                size="small"
                typographyName="Button2"
                variant="secondary-border"
                onClick={handleAddProfile}
                label={PROFILE_LIST.BUTTON_LABEL}
                iconPosition="right"
                dataTestId="add-profile-button"
                icon={<PlusIcon color="blue700" />}
              />
            </div>
          </div>
          <div className={styles.table}>
            {formattedBodyItems && (
              <Table
                headItems={formattedHeadItems}
                bodyItems={formattedBodyItems}
                initItemsWidth={['151', '288', '165', '129', '120']}
                dataTestId="profile-list-table"
              />
            )}
          </div>
        </div>
        <footer className={styles.pagination}>
          <Pagination
            current={page}
            pageItemCount={companyList?.companies.pageItems.length}
            total={companyList?.companies?.totalItems || 0}
            pageSize={pageSize}
            changePageSize={changePageSize}
            changePage={handleChange}
            pageSizeLabel={PAGINATION_DROPDOWN_LABEL}
          />
        </footer>
      </Container>
    </div>
  )
}
