import { Button } from '@frontend/design_system'
import { Typography } from 'ui/atoms/Typography'
import ConfirmImage from 'assets/img/ConfirmModal.png'
import { Modal } from 'ui/molecules/Modal'
import styles from './styles.module.scss'
import { SuccessModalProps } from './types'

export const SuccessModal = ({
  onClose,
  title,
  subTitle,
  buttonLabel,
  onConfirm,
}: SuccessModalProps) => {
  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm()
    } else {
      onClose()
    }
  }

  return (
    <Modal
      isDelimiter
      headerSize="large"
      position="center-horizontal"
      onClose={onClose}
      title={title}
    >
      <div className={styles.content}>
        {subTitle && (
          <Typography name="body2WMedium" color="grey500">
            {subTitle}
          </Typography>
        )}
        <img src={ConfirmImage} className={styles.image} />
        {buttonLabel && (
          <div className={styles['button-wrapper']}>
            <Button
              typographyName="Button2"
              label={buttonLabel}
              dataTestId="success-modal-button"
              onClick={handleConfirm}
            />
          </div>
        )}
      </div>
    </Modal>
  )
}
