import { useState, useEffect, useMemo } from 'react'
import { MENU_NAMES } from 'texts/menuNames'
import { TOOLTIP, TABLE, PRICE_INFO } from 'texts/dropoffFee'
import { EditableCell, Table } from 'ui/molecules/Table'
import {
  dropOffFeeApi,
  DropOffFeeApiTypes,
  referencesApi,
  ReferencesApiTypes,
} from 'api'
import { Pagination, Typography } from '@frontend/design_system'
import { ArrowLeftRightIcon, InfoIcon } from 'ui/icons'
import { Tooltip } from 'ui/atoms/Tooltip'
import { useApiRequest } from 'hooks/useApiRequest'
import {
  DEFAULT_INITIAL_PAGE,
  DEFAULT_INITIAL_PAGE_SIZE,
} from 'constants/pagination'
import { PAGINATION_DROPDOWN_LABEL } from 'texts/uiTexts'
import { breadcrumbs } from 'constants/breadcrumbs'
import { FilterRoute } from './components/FilterRoute'
import { PriceInfo } from './components/PriceInfo'
import { isOperatorSelector } from 'redux/login/selectors'
import { useAppSelector } from 'redux/hooks'
import { Container } from 'ui/molecules/Container'
import { useCompanyId } from 'hooks/useCompanyId'
import styles from './styles.module.scss'

export const DropoffFeeList = () => {
  const [page, setPage] = useState(DEFAULT_INITIAL_PAGE)
  const [pageSize, setPageSize] = useState(DEFAULT_INITIAL_PAGE_SIZE)
  const [options, setOptions] = useState<DropOffFeeApiTypes.DropOffData>({})
  const [locations, setLocations] = useState<ReferencesApiTypes.Location[]>()
  const [dropOffFeeData, setDropOffFeeData] =
    useState<DropOffFeeApiTypes.GetDropOffFeeListResponse | null>(null)
  const isOperator = useAppSelector(isOperatorSelector)
  const companyId = useCompanyId()
  const isDisabledFilter = !(
    dropOffFeeData && dropOffFeeData?.routes.pageItems.length > 1
  )

  const dropOffFeeListRequest = useApiRequest(
    (
      page: number,
      pageSize: number,
      companyId: number,
      options: DropOffFeeApiTypes.DropOffData
    ) => dropOffFeeApi.getDropOffFeeList(page, pageSize, companyId, options)
  )

  const dropOffFeeRequest = useApiRequest(
    (
      fromHirePointId: number,
      toHirePointId: number,
      data: DropOffFeeApiTypes.SetDropOffFeeData
    ) => dropOffFeeApi.setDropOffFee(fromHirePointId, toHirePointId, data)
  )

  const getAllCompanyHirePointsRequest = useApiRequest((companyId: number) =>
    referencesApi.getAllCompanyHirePoints(String(companyId), true)
  )

  const changePageSize = (value: string) => {
    setPageSize(value)
    setPage(DEFAULT_INITIAL_PAGE)
  }

  const handleChange = (pageNumber: number) => {
    setPage(pageNumber)
  }

  const saveRouteValue = async (
    toLocationId: number,
    fromLocationId: number,
    value?: number | string
  ) => {
    dropOffFeeRequest.apiRequest(fromLocationId, toLocationId, {
      companyId,
      fromHirePointId: fromLocationId,
      toHirePointId: toLocationId,
      price: value ? Number(value) : null,
    })
  }

  const handleRouteChange = ({
    pickUpLocation,
    dropOffLocation,
  }: {
    pickUpLocation?: string
    dropOffLocation?: string
  }) => {
    setOptions({
      toLocationContractId: Number(dropOffLocation),
      fromLocationContractId: Number(pickUpLocation),
    })
  }

  const formattedHeadItems = [
    {
      value: (
        <div className={styles['header-pick-up']}>
          <div className={styles['header-title-block']}>
            <Typography
              name="Subtitle3"
              className={styles['header-first-title']}
            >
              {`${TABLE.PICK_UP} - ${TABLE.DROP_OFF}`}
            </Typography>
            <ArrowLeftRightIcon size="medium" color="grey900" />
            <Typography
              name="Subtitle3"
              className={styles['header-second-title']}
            >
              {`${TABLE.PICK_UP} - ${TABLE.DROP_OFF}`}
            </Typography>
          </div>
          <div>
            <FilterRoute
              items={locations}
              onChange={handleRouteChange}
              isDisabled={isDisabledFilter}
            />
          </div>
        </div>
      ),
    },
    {
      value: (
        <div className={styles['header-price']}>
          <Typography name="Subtitle3">{`${PRICE_INFO.TITLE}`}</Typography>
          <Tooltip
            arrowPosition="center"
            popupContent={<PriceInfo />}
            placement="bottom"
          >
            <InfoIcon color="blue700" size="small" />
          </Tooltip>
        </div>
      ),
    },
  ]

  const formattedBodyItems = useMemo(
    () =>
      dropOffFeeData?.routes.pageItems.map((el) => ({
        items: [
          <div className={styles['location-info']}>
            <Typography
              name="Subtitle7"
              Tag="div"
              className={styles['from-location-item']}
            >
              {`${el.fromLocationName} - ${el.toLocationName}`}
            </Typography>
            <Typography
              name="Subtitle7"
              Tag="div"
              className={styles['to-location-item']}
            >
              {`${el.toLocationName} - ${el.fromLocationName}`}
            </Typography>
          </div>,
          <EditableCell
            value={el.price}
            handleUpdate={(price) =>
              saveRouteValue(el.toHirePointId, el.fromHirePointId, price)
            }
            dataTestId="dropoff-editable-cell"
          />,
        ],
        key: el.fromHirePointId + el.toHirePointId,
      })),
    [dropOffFeeData?.routes.pageItems]
  )

  useEffect(() => {
    const optionData: DropOffFeeApiTypes.DropOffData = {}
    if (!!options.fromLocationContractId) {
      optionData.fromLocationContractId = options.fromLocationContractId
    }
    if (!!options.toLocationContractId) {
      optionData.toLocationContractId = options.toLocationContractId
    }
    const getDropOffFeeDatA = async () => {
      const response = await dropOffFeeListRequest.apiRequest(
        page - 1,
        Number(pageSize),
        companyId,
        optionData
      )
      if (response && response.data) {
        setDropOffFeeData(response.data)
      }
    }
    getDropOffFeeDatA()
  }, [page, pageSize, companyId, options])

  useEffect(() => {
    const getLocationsList = async () => {
      const response = await getAllCompanyHirePointsRequest.apiRequest(
        companyId
      )
      if (response && response.data) {
        setLocations(response.data.items)
      }
    }
    getLocationsList()
  }, [companyId])

  return (
    <div>
      <Container
        breadcrumbList={isOperator ? [breadcrumbs.profileList] : []}
        currentPageLabel={MENU_NAMES.DROP_OFF_FEE}
        title={MENU_NAMES.DROP_OFF_FEE}
        withNavigation={isOperator}
        tooltip={TOOLTIP}
        loading={dropOffFeeListRequest.loading}
      >
        <div className={styles.content}>
          <div className={styles.list}>
            <div className={styles.table}>
              {formattedBodyItems && (
                <Table
                  headItems={formattedHeadItems}
                  bodyItems={formattedBodyItems}
                  initItemsWidth={['653', '200']}
                  dataTestId="dropoff-fee-table"
                />
              )}
            </div>
          </div>
          <footer className={styles.pagination}>
            <Pagination
              current={page}
              pageItemCount={dropOffFeeData?.routes.totalPages || 0}
              total={dropOffFeeData?.routes.totalItems || 0}
              pageSize={pageSize}
              changePageSize={changePageSize}
              changePage={handleChange}
              pageSizeLabel={PAGINATION_DROPDOWN_LABEL}
            />
          </footer>
        </div>
      </Container>
    </div>
  )
}
