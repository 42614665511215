import { Button } from '@frontend/design_system'
import { ADD } from 'texts/common'
import styles from './styles.module.scss'
import { joinClasses } from 'utils/joinClasses'
import { CANCEL, SAVE } from 'texts/uiTexts'

interface IActionButtonsProps {
  isOffset?: boolean
  disabled?: boolean
  onCancel?: VoidFunction
  loading: boolean
  submitLabel?: string
}

export const ActionButtons = ({
  isOffset,
  disabled,
  onCancel,
  loading,
  submitLabel = '',
}: IActionButtonsProps) => (
  <div className={joinClasses(styles.btns, [styles.offset, isOffset])}>
    {onCancel && (
      <div className={styles.btn}>
        <Button
          label={CANCEL}
          typographyName="body1WMedium"
          size="large"
          variant="secondary-border"
          onClick={onCancel}
        />
      </div>
    )}
    <div className={styles.btn}>
      <Button
        htmlType="submit"
        label={submitLabel || (onCancel ? SAVE : ADD)}
        typographyName="body1WMedium"
        size="large"
        disabled={disabled}
        loading={loading}
      />
    </div>
  </div>
)
